import { Collapse } from 'antd'
import React from 'react'

import { StyledCollapse } from './Results.styles'

const { Panel } = Collapse

interface ResultMetaProps {
  isFile: boolean
  contentDescription: string
  exportReason: string
}

const ResultMeta: React.FC<ResultMetaProps> = ({ isFile, contentDescription, exportReason }) => {
  return (
    <div data-testid="result-meta">
      <StyledCollapse defaultActiveKey={exportReason ? ['reason'] : ['description']} expandIconPosition="end">
        {contentDescription && (
          <Panel key={'description'} header={isFile ? 'File description' : 'Folder description'}>
            <div>{contentDescription}</div>
          </Panel>
        )}
        {exportReason && (
          <Panel key={'reason'} header={'Reason for export'}>
            <div>{exportReason}</div>
          </Panel>
        )}
      </StyledCollapse>
    </div>
  )
}

export default ResultMeta
