import React from 'react'
import { Tabs } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'

import { ResultsType } from 'enums'
import { CommonPageWrapper, Title, TitleWrapper } from 'core/Common.styles'
import ExportRequestsTable from './ExportRequestsTable'
import NotFound from 'modules/general/not-found/NotFound'

export const ExportRequestsPage: React.FC = () => {
  const { tabId } = useParams()
  const navigate = useNavigate()

  if (tabId && !['files', 'analysis'].includes(tabId)) return <NotFound />

  return (
    <CommonPageWrapper>
      <TitleWrapper>
        <Title>Data export requests</Title>
      </TitleWrapper>

      <Tabs activeKey={tabId || 'files'} onChange={(tab: string) => navigate(`/export-requests/${tab}`)}>
        <Tabs.TabPane tab={'Files / Folders'} key={'files'}>
          <ExportRequestsTable resultType={ResultsType.FilesFolders} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Analysis'} key={'analysis'}>
          <ExportRequestsTable resultType={ResultsType.Analysis} />
        </Tabs.TabPane>
      </Tabs>
    </CommonPageWrapper>
  )
}

export default ExportRequestsPage
