import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import type { ColumnsType } from 'antd/es/table'

import { getSubscriptions } from 'services/subscriptions'
import Search from 'components/Search/Search'
import TablePageSizeSelect from 'components/TablePageSizeSelect'
// import DownloadTableButton from 'components/DownloadTableButton'
import useDebounce from 'hooks/useDebounce'
import { formatDuration } from 'utils/duration'

import { TableContainer, Title, TitleWrapper, Section, CommonPageWrapper } from 'core/Common.styles'
import { AntdTableWrapperV2 } from 'core/AntdTable.styles'
import { formatCost } from 'modules/subscriptions/overview/helpers'

import SubscriptionStatus from './SubscriptionStatus'
import { SearchContainer, ActionsContainer, StyledTypedTable } from './SubscriptionsTable.styles'
import { formatDate } from 'utils/date'

const DEFAULT_PAGE_SIZE = 10
const FIRST_PAGE = 1
const SEARCH_DEBOUNCE_DELAY = 200

interface SubscriptionsListProps {}

export const SubscriptionsTable: React.FC<SubscriptionsListProps> = () => {
  const [tableState, setTableState] = useState<{ page: number; pageSize: number }>({
    page: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const navigate = useNavigate()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, SEARCH_DEBOUNCE_DELAY)

  const { data: paginatedResponse, isLoading } = useQuery<{}, AxiosError, SubscriptionsResponse>(
    ['subscriptions', tableState.page, tableState.pageSize, debouncedSearchTerm],
    async () => {
      return getSubscriptions(debouncedSearchTerm, tableState.page, tableState.pageSize)
    }
  )

  useEffect(() => {
    setTableState({ page: FIRST_PAGE, pageSize: tableState.pageSize })
    //eslint-disable-next-line
  }, [searchTerm])

  const handleSetPageSize = (newPageSize: number) => {
    setTableState({ page: FIRST_PAGE, pageSize: newPageSize })
  }

  const handleOnRow = (row: any) => {
    return {
      onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        navigate(`/subscriptions/${row._id}`)
      }
    }
  }

  const columns: ColumnsType<Subscription> = [
    {
      title: 'Organisation name',
      key: 'organisation',
      render: (text: string, subscription: Subscription) => (
        <>
          <SubscriptionStatus text={subscription.organization} status={subscription.status} />
        </>
      )
    },
    {
      title: 'Leading applicant',
      key: 'leadingApplicant',
      render: (text: string, subscription: Subscription) => subscription.requestingUser?.email
    },
    {
      title: 'Dataset',
      key: 'dataset',
      dataIndex: 'dataset'
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (text: string, subscription: Subscription) => formatDuration(subscription.duration)
    },
    {
      title: 'Auto-renewal',
      render: (text: string, subscription: Subscription) => (subscription.isAutoRenewal ? 'Yes' : 'No')
    },
    {
      title: 'Date applied',
      render: (text: string, subscription: Subscription) => formatDate(subscription.updatedAt)
    },
    {
      title: 'Subscription starts',
      render: (text: string, subscription: Subscription) => formatDate(subscription.approvedAt)
    },
    {
      title: 'Subscription ends',
      render: (text: string, subscription: Subscription) => formatDate(subscription.endsAt)
    },
    {
      title: 'Revenue',
      key: 'revenue',
      render: (text: string, subscription: Subscription) => formatCost(subscription.revenue)
    }
  ]

  return (
    <CommonPageWrapper>
      <TitleWrapper>
        <Title>Your subscribers</Title>
      </TitleWrapper>
      <Section>
        <TableContainer>
          <SearchContainer>
            <Search handleChange={setSearchTerm} term={searchTerm} placeholder={'Search'} />
            <ActionsContainer>
              {/* TODO re-enable this when api is ready */}
              {/*<DownloadTableButton*/}
              {/*  apiDefinition={() => getSubscriptionsAsCsv()}*/}
              {/*  fileName={'subscriptions.csv'}*/}
              {/*  disabled={!paginatedResponse?.data?.length}*/}
              {/*/>*/}
            </ActionsContainer>
          </SearchContainer>
          <AntdTableWrapperV2 data-testid={'subscriptions-table-wrapper'} className={isLoading ? 'isLoading' : ''}>
            <StyledTypedTable
              data-testid="subscriptions-table"
              className={'isClickable'}
              columns={columns}
              dataSource={paginatedResponse?.data}
              onRow={handleOnRow}
              loading={isLoading}
              pagination={{
                showSizeChanger: false,
                pageSize: tableState.pageSize,
                current: tableState.page,
                total: paginatedResponse?.pagination?.totalCount || 0,
                onChange: (pageNumber: number, pageSize: number) => {
                  setTableState({ page: pageSize !== tableState.pageSize ? FIRST_PAGE : pageNumber, pageSize })
                }
              }}
            />
            <TablePageSizeSelect
              total={paginatedResponse?.pagination?.totalCount || 0}
              pageSize={tableState.pageSize}
              setPageSize={handleSetPageSize}
            />
          </AntdTableWrapperV2>
        </TableContainer>
      </Section>
    </CommonPageWrapper>
  )
}

export default SubscriptionsTable
