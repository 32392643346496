export const parseDuration = (durationISO: string): DurationIntervals => {
  const durationRegex = /(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?(?:(\d+)h)?(?:(\d+)m)?/
  const matches = durationISO.match(durationRegex)
  const [, years, months, days, hours, minutes] = matches || []
  return {
    years: years ? parseInt(years) : 0,
    months: months ? parseInt(months) : 0,
    days: days ? parseInt(days) : 0,
    hours: hours ? parseInt(hours) : 0,
    minutes: minutes ? parseInt(minutes) : 0
  }
}

// Returns a string representation of an ISO duration string.
// It assumes that if a duration interval is 0 (e.g. if months  is 0) then it should not be displayed.
export const formatDuration = (durationISO: string): string => {
  const intervals = parseDuration(durationISO)
  const strings = []
  if (intervals.years) strings.push(`${intervals.years} ${intervals.years === 1 ? 'year' : 'years'}`)
  if (intervals.months) strings.push(`${intervals.months} ${intervals.months === 1 ? 'month' : 'months'}`)
  if (intervals.days) strings.push(`${intervals.days} ${intervals.days === 1 ? 'day' : 'days'}`)
  if (intervals.hours) strings.push(`${intervals.hours} ${intervals.hours === 1 ? 'hour' : 'hours'}`)
  if (intervals.minutes) strings.push(`${intervals.minutes} ${intervals.minutes === 1 ? 'minute' : 'minutes'}`)

  return strings.length ? strings.join(', ') : ''
}
