import React from 'react'

interface AuthContextType {
  autoLogin(user: UserAuth): void
  login(username: string, password: string, otp?: string): Promise<UserAuth | { otp: boolean }>
  logout(): void
  user?: UserAuth
  isLoading?: boolean
  isFetching?: boolean
}

export const AuthContext = React.createContext<AuthContextType>(undefined!)
