import { useMutation, useQuery, useQueryClient } from 'react-query'

import { getSubscription, approveSubscription, rejectSubscription } from 'services/subscriptions'

export interface UseSubscriptionReturn {
  isLoading: boolean
  data: Subscription | undefined
  isFetching: boolean
  isError: boolean
  handleApprove: () => Promise<void>
  handleReject: (reason: string) => Promise<void>
  isApprovalLoading: boolean
  isRejectLoading: boolean
  approvalHasError: boolean
  rejectionHasError: boolean
}

export const useSubscription = (
  id: string,
  onApproveSuccess?: () => void,
  onRejectSuccess?: () => void
): UseSubscriptionReturn => {
  const queryClient = useQueryClient()

  const queryKey = ['subscription', id]

  const { isLoading, data, isFetching, isError } = useQuery(
    queryKey,
    async () => {
      return getSubscription(id)
    },
    { retry: false }
  )

  const {
    mutateAsync: handleApprove,
    isLoading: isApprovalLoading,
    isError: approvalHasError
  } = useMutation<void, unknown, void>(
    queryKey,
    async () => {
      if (!id) return
      await approveSubscription(id)
    },
    {
      retry: 1,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
        if (typeof onApproveSuccess === 'function') onApproveSuccess()
      }
    }
  )

  const {
    mutateAsync: handleReject,
    isLoading: isRejectLoading,
    isError: rejectionHasError
  } = useMutation<void, unknown, string>(
    queryKey,
    async (reason: string) => {
      if (!id || !reason) return

      await rejectSubscription(id, reason)
    },
    {
      retry: 1,
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
        if (typeof onRejectSuccess === 'function') onRejectSuccess()
      }
    }
  )

  return {
    isLoading,
    data,
    isFetching,
    isError,
    handleApprove,
    handleReject,
    isApprovalLoading,
    isRejectLoading,
    approvalHasError,
    rejectionHasError
  }
}

export default useSubscription
