import styled from 'styled-components'
import { labelLargeMediumStyles } from '@lifebit-ai/lds'

export const StyledStepsWrapper = styled.div`
  .ant-steps.ant-steps-navigation {
    border-bottom: 1px solid ${({ theme }) => theme.color.border.default};
    .ant-steps-item {
      text-align: left;
      padding-inline-start: 0;
      &:after {
        display: none;
      }
      .ant-steps-item-container {
        margin-inline-start: 0;
      }

      .ant-steps-item-icon {
        background-color: ${({ theme }) => theme.color.background.main};
        border-color: ${({ theme }) => theme.color.background.main};

        .ant-steps-icon {
          ${labelLargeMediumStyles};
          color: ${({ theme }) => theme.color.content.disabled};
        }
      }

      .ant-steps-item-title {
        ${labelLargeMediumStyles};
        color: ${({ theme }) => theme.color.content.disabled};
        line-height: 32px;
      }

      &.ant-steps-item-active {
        .ant-steps-item-icon {
          background-color: ${({ theme }) => theme.color.feedback.action.subtle};
          border-color: ${({ theme }) => theme.color.feedback.action.subtle};

          .ant-steps-icon {
            color: ${({ theme }) => theme.color.feedback.action.default};
          }
        }
        .ant-steps-item-title {
          color: ${({ theme }) => theme.color.feedback.action.default};
        }
      }
    }
  }
`
