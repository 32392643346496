import { Table } from 'antd'
import styled from 'styled-components'

import { antdScrollableTableStyles } from 'core/AntdTable.styles'

export const StyledTypedTable = styled(Table<Result>)`
  ${antdScrollableTableStyles}

  &:hover {
    cursor: pointer;
  }
`

export const StyledEmail = styled.span`
  text-transform: lowercase;
`
