import styled, { DefaultTheme } from 'styled-components'
import { Button, labelDefaultSemiboldStyles } from '@lifebit-ai/lds'
import { ResultsState } from 'enums'
import { CommonContainer } from '../../../core/Common.styles'

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.size.space['05']};
  margin-top: ${({ theme }) => theme.size.space['05']};
`

export const StateWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.size.space['04']};
  &:nth-child(1) {
    margin-top: ${({ theme }) => theme.size.space['01']};
  }
`

export const StateText = styled.div`
  color: ${({ theme }) => theme.color.base.neutral.black};
  text-transform: capitalize;
`

export const StyledHeaderName = styled.div`
  text-transform: capitalize;
`

export const SearchContainer = styled.div`
  padding-top: ${({ theme }) => theme.size.space['06']};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.size.layout['01']};

  > span {
    margin-bottom: 0;
  }

  input {
    min-width: 240px;
  }
`

export const DownloadTableButton = styled(Button)`
  ${labelDefaultSemiboldStyles};
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  min-height: 40px;
`

const getStatusIconColor = (status: ResultsState, theme: DefaultTheme) => {
  switch (status) {
    case ResultsState.Complete:
      return theme.color.feedback.success.default
    case ResultsState.PendingVerification:
      return theme.color.feedback.warning.default
    case ResultsState.Rejected:
      return theme.color.feedback.danger.default
    default:
      return theme.color.content.main
  }
}

export const StatusIconWrapper = styled.div<{ status: ResultsState }>`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ status, theme }) => getStatusIconColor(status, theme)};
    }
  }
`

export const PlaceholderMessage = styled(CommonContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55vh;
  min-height: 150px;
  color: ${({ theme }) => theme.color.content.secondary};
`
