import { Input, icons } from '@lifebit-ai/lds'
import { LoginTypes } from 'enums'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import * as Yup from 'yup'

import { extractErrorMessage } from 'core/utils'
import useQueryParams from 'hooks/useQueryParams'
import { AppleMail, Gmail, OutlookMail, YahooMail } from 'img'
import { loginWithMagic } from 'services/auth'
import { LoginWithMagicParams } from 'services/types'

import AppLoadingSpinner from '../../core/AppLoadingSpinner'
import AuthWrapper from './AuthWrapper'
import * as styles from './LoginMagicLink.styles'
import { MagicLinkErrorReason } from './LoginMagicLinkCallback'

export const APPLE_MAIL_URL = 'https://www.icloud.com/mail'
export const GMAIL_MAIL_URL = 'https://mail.google.com'
export const OUTLOOK_MAIL_URL = 'https://login.live.com'
export const YAHOO_MAIL_URL = 'https://login.yahoo.com'

export const MAGIC_LINK_RESEND_BUTTON_TEST_ID = 'magic-link-resend-button-test-id'
export const MAGIC_LINK_RETURN_BUTTON_TEST_ID = 'magic-link-return-button-test-id'
export const MAGIC_LINK_FORM_EMAIL_FIELD_TEST_ID = 'magic-link-form-email-field-test-id'
export const MAGIC_LINK_SEND_BUTTON_TEST_ID = 'magic-link-send-button-test-id'
export const MAGIC_LINK_CONFIRMATION_CONTAINER_TEST_ID = 'magic-link-confirmation-container-test-id'

interface MagicLinkFormValues {
  email: string
}

const LoginMagicLink: React.FC = () => {
  const [isConfirmationOpened, setIsConfirmationOpened] = useState(false)
  const queryParams = useQueryParams()
  const errorMessage = queryParams.get('error')

  const mutation = useMutation({
    mutationFn: (params: LoginWithMagicParams) => loginWithMagic(params)
  })

  const { errors, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = useFormik<MagicLinkFormValues>({
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Email is required').email('Invalid email')
    }),
    onSubmit: async values => {
      //check here why we need app url:https://lifebit.atlassian.net/wiki/spaces/LPE/pages/1036779547/CORE+-+Magic+login+on+Airlock#POST-%2Fauth%2Fmagic-login
      await mutation.mutateAsync({ email: values.email, appUrl: window.location.origin })
      setIsConfirmationOpened(true)
    }
  })

  if (isConfirmationOpened) {
    if (mutation.isLoading) {
      return (
        <AuthWrapper>
          <styles.ConfirmationContainer>
            <AppLoadingSpinner text="Sending magic link..." />
          </styles.ConfirmationContainer>
        </AuthWrapper>
      )
    }

    return (
      <AuthWrapper>
        <styles.ConfirmationContainer data-testid={MAGIC_LINK_CONFIRMATION_CONTAINER_TEST_ID}>
          <styles.Header>
            <styles.HeaderTitle>Check your inbox</styles.HeaderTitle>
            <styles.HeaderDescription>
              We sent a magic link to <span>{values.email}</span>. If you have not received it in a few minutes, check
              your spam folder.
            </styles.HeaderDescription>
          </styles.Header>

          <styles.Body>
            <styles.BodyItem onClick={() => window.open(APPLE_MAIL_URL, '_blank')}>
              <AppleMail />
              <styles.MailTitle>Apple Mail</styles.MailTitle>
              <icons.OpenSmall />
            </styles.BodyItem>
            <styles.BodyItem onClick={() => window.open(GMAIL_MAIL_URL, '_blank')}>
              <Gmail />
              <styles.MailTitle>Gmail</styles.MailTitle>
              <icons.OpenSmall />
            </styles.BodyItem>
            <styles.BodyItem onClick={() => window.open(OUTLOOK_MAIL_URL, '_blank')}>
              <OutlookMail />
              <styles.MailTitle>Outlook</styles.MailTitle>
              <icons.OpenSmall />
            </styles.BodyItem>
            <styles.BodyItem onClick={() => window.open(YAHOO_MAIL_URL, '_blank')}>
              <YahooMail />
              <styles.MailTitle>Yahoo Mail</styles.MailTitle>
              <icons.OpenSmall />
            </styles.BodyItem>
          </styles.Body>

          <styles.Footer>
            <div>
              Didn’t receive an email?{' '}
              <span
                data-testid={MAGIC_LINK_RESEND_BUTTON_TEST_ID}
                onClick={() => mutation.mutate({ email: values.email, appUrl: window.location.origin })}
              >
                Resend it.
              </span>
            </div>
            <div>
              Wrong email?{' '}
              <styles.InternalLink
                to={`/login?type=${LoginTypes.MagicLink}`}
                data-testid={MAGIC_LINK_RETURN_BUTTON_TEST_ID}
                onClick={() => {
                  setIsConfirmationOpened(false)
                  resetForm()
                }}
              >
                Try a different email.
              </styles.InternalLink>
            </div>
          </styles.Footer>
        </styles.ConfirmationContainer>
      </AuthWrapper>
    )
  }

  const renderHeader = () => {
    if (errorMessage === MagicLinkErrorReason.Expired) {
      return (
        <>
          <styles.Title>Magic link expired</styles.Title>
          <styles.Description>This magic link has expired. Please request a new one below.</styles.Description>
        </>
      )
    }

    if (errorMessage === MagicLinkErrorReason.Unknown) {
      return (
        <>
          <styles.Title>Magic link expired</styles.Title>
          <styles.Description>This magic link has expired. Please request a new one below.</styles.Description>
        </>
      )
    }

    return (
      <>
        <styles.Title>Sign in</styles.Title>
        <styles.Description>We’ll email you a link to sign in to Lifebit Airlock.</styles.Description>
      </>
    )
  }

  return (
    <AuthWrapper>
      {renderHeader()}

      <styles.Form onSubmit={handleSubmit}>
        <styles.FormRow>
          <Input
            {...getFieldProps('email')}
            label="Email"
            placeholder="Enter your email"
            data-testid={MAGIC_LINK_FORM_EMAIL_FIELD_TEST_ID}
            status={{
              type: errors.email ? 'error' : 'success',
              text: errors.email
            }}
          />
        </styles.FormRow>

        <styles.FooterContainer>
          <styles.FormButton
            htmlType="submit"
            size="large"
            data-testid={MAGIC_LINK_SEND_BUTTON_TEST_ID}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Send magic link
          </styles.FormButton>

          {mutation.error ? (
            <styles.FormRow>
              <styles.ErrorText>{extractErrorMessage(mutation.error)}</styles.ErrorText>
            </styles.FormRow>
          ) : null}
        </styles.FooterContainer>
      </styles.Form>
    </AuthWrapper>
  )
}

export default LoginMagicLink
