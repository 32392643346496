import { labelDefaultRegularStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

export const AuthWrapperContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

export const Wrapper = styled.div`
  flex-grow: 1;
  background: ${({ theme }) => theme.color.background.main};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LogoContainer = styled.div`
  margin: ${({ theme }) => theme.size.space['10']} auto ${({ theme }) => theme.size.space['07']};
`

export const FormWrapper = styled.div`
  margin: 0 auto;
  display: inline;
  border-radius: ${({ theme }) => theme.size.border.radius['03']};
  padding: 0;
  background: ${({ theme }) => theme.color.background.surface};
  box-shadow: ${({ theme }) => theme.shadow['02']};
  min-width: 590px;
  max-width: 754px;
  width: 100%;
`

export const ChildrenWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.size.layout['05']};
  padding-left: ${({ theme }) => theme.size.layout['05']};
  padding-right: ${({ theme }) => theme.size.layout['05']};
  padding-top: ${({ theme }) => theme.size.layout['05']};
`

export const Footer = styled.div`
  flex-grow: 0;
  background: ${({ theme }) => theme.color.background.highlight};
`

export const FooterTop = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.size.space['10']};
  justify-content: center;
  padding-bottom: ${({ theme }) => theme.size.space['06']};
  padding-top: ${({ theme }) => theme.size.space['09']};
`

export const FooterTopElement = styled.a`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
`

export const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.space['07']} 0;
  border-top: 1px solid ${({ theme }) => theme.color.border.subtle};
  max-width: 1024px;
  margin: 0 auto;
`

export const FooterBottomSocial = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.size.space['05']};

  a {
    width: 35px;
    height: 35px;
    border: 1px solid ${({ theme }) => theme.color.border.input};
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`

export const FooterBottomRights = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
`
