import { SignoutIcon } from 'img'
import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  box-shadow: ${({ theme }) => theme.shadow['02']};
  border-radius: ${({ theme }) => theme.component.modal.size.border.radius};
  width: 350px;
  overflow: hidden;
`

export const Footer = styled.div``


const smallIconStyle = css`
  width: ${({ theme }) => theme.size.icon.small};
  height: ${({ theme }) => theme.size.icon.small};
  color: ${({ theme }) => theme.color.content.main};

  rect {
    fill: none;
  }
`

const btnStyle = css`
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  padding: ${({ theme }) => theme.size['03']} ${({ theme }) => theme.size['05']};
  gap: ${({ theme }) => theme.size['02']};
  color: ${({ theme }) => theme.color.content.main};
  border-radius: ${({ theme }) => theme.size['02']};

  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.color.feedback.action.subtle};
  }
`

export const SignoutBtn = styled.button`
  ${btnStyle}
`

export const StyledSignoutIcon = styled(SignoutIcon)`
  ${smallIconStyle};

  path,
  g {
    stroke: ${({ theme }) => theme.color.content.main};
  }
`
