import styled from 'styled-components'
import { icons, Loader } from '@lifebit-ai/lds'

export const DownloadIcon = styled(icons.DownloadSmall)<{ disabled: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

export const StyledLoader = styled(Loader)`
  svg {
    width: 16px;
    height: 16px;
  }
`
