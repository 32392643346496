import { getStorageValue, removeStorageValue } from 'core/local-storage'
import { authenticateUser } from 'services/user'

export const USER_SESSION_LOCAL_STORAGE_KEY = 'userSession'

export const getCurrentUser = (): UserAuth | undefined => {
  const userSession = getStorageValue(USER_SESSION_LOCAL_STORAGE_KEY)
  return userSession ? JSON.parse(userSession) : undefined
}

export const signIn = async (
  username: string,
  password: string,
  otp?: string
): Promise<UserAuth | { otp: boolean } | never> => {
  return authenticateUser(username, password, otp)
}

export const signOut = (): void => {
  removeStorageValue(USER_SESSION_LOCAL_STORAGE_KEY)
}
