import React, { useState } from 'react'
import { Steps } from 'antd'
import { Alert, Button, icons, Modal } from '@lifebit-ai/lds'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import { SubscriptionMemberActions } from 'enums'

import ReviewStep from './ReviewStep'
import AuthStep from './AuthStep'
import { BackButton, Buttons, ErrorMsg, SubmitButton, Wrapper } from './BulkAuthModal.styles'
import { StyledStepsWrapper } from './Steps.styles'

export interface BulkAuthFormData {
  username: string
  password: string
  isBulkOperation: boolean
  reason?: string
}

interface Props {
  actionType: SubscriptionMemberActions
  member: SubscriptionMember
  errorMessage?: string
  action(member: SubscriptionMember, managerUsername: string, managerPassword: string, isBulkOperation: boolean): void
  onClose(): void
  isLoading: boolean
  isError: boolean
  isOpen?: boolean
}

// TODO Until the api will be ready, the flag supportsBulkOperation is introduced;
//  All actions should finally support bulk operations and the flag should be redundant then.
const getActionInfo = (
  actionType: SubscriptionMemberActions,
  member: SubscriptionMember
): { title: string; message: string; applyToAllLabel: string; supportsBulkOperation: boolean } => {
  switch (actionType) {
    case SubscriptionMemberActions.Approve:
      return {
        title: 'Confirm data access?',
        message: `Do you want to grant data access for ${member?.name} (${member?.email})?`,
        applyToAllLabel: 'Apply to all subscription members',
        supportsBulkOperation: true
      }
    case SubscriptionMemberActions.ApproveExtra:
      return {
        title: 'Confirm data access?',
        message: `Do you want to grant data access for ${member?.name} (${member?.email})?`,
        applyToAllLabel: 'Apply to all members newly added',
        supportsBulkOperation: true
      }
    case SubscriptionMemberActions.Revoke:
      return {
        title: 'Revoke data access?',
        message: `Do you want to revoke data access for ${member?.name} (${member?.email})?`,
        applyToAllLabel: 'Apply to all members',
        supportsBulkOperation: false
      }
    case SubscriptionMemberActions.Remove:
      return {
        title: 'Confirm subscription member removal?',
        message: `Do you confirm permanent removal of the subscription member ${member?.name} (${member?.email})?`,
        applyToAllLabel: 'Apply to all subscription members removed',
        supportsBulkOperation: false
      }
    default:
      return { title: '', message: '', applyToAllLabel: '', supportsBulkOperation: false }
  }
}

const initialState = {
  isPasswordVisible: false,
  current: 0
}

const BulkAuthModal: React.FC<Props> = ({
  action,
  actionType,
  member,
  onClose,
  isOpen,
  isLoading,
  isError,
  errorMessage = 'There was an error while handling the request. Please try again or contact the administrator.'
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(initialState.isPasswordVisible)
  const [current, setCurrent] = useState(initialState.current)

  const { title, message, supportsBulkOperation, applyToAllLabel } = getActionInfo(actionType, member)

  const resetState = () => {
    setIsPasswordVisible(initialState.isPasswordVisible)
    setCurrent(initialState.current)
  }

  const onSubmit = (values: BulkAuthFormData) => {
    action(member, values.username, values.password, values.isBulkOperation)
  }

  const hasReason = actionType === SubscriptionMemberActions.Revoke

  const validationSchema = Yup.object().shape({
    username: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    ...(hasReason && {
      reason: Yup.string().required('Reason is required')
    })
  })

  const initialValues: BulkAuthFormData = {
    username: '',
    password: '',
    isBulkOperation: false,
    ...(hasReason && { reason: '' })
  }

  const steps = [
    {
      title: 'Review',
      status: 'process',
      content: (
        <ReviewStep
          message={message}
          supportsBulkOperation={supportsBulkOperation}
          applyToAllLabel={applyToAllLabel}
          hasReason={hasReason}
        />
      )
    },
    {
      title: 'Confirm',
      status: 'process',
      content: <AuthStep isPasswordVisible={isPasswordVisible} setIsPasswordVisible={setIsPasswordVisible} />
    }
  ]
  const items = steps.map((item, i) => ({
    key: item.title,
    title: item.title,
    // Override antd default status in order to no display the check icon for previous steps
    status: i === current ? ('process' as const) : ('wait' as const)
  }))

  const hasNextStep = current < steps.length - 1
  const isFinalStep = current === steps.length - 1

  const next = (e: React.MouseEvent) => {
    e.preventDefault()
    setCurrent(current + 1)
  }

  const prev = (e: React.MouseEvent) => {
    e.preventDefault()
    setCurrent(current - 1)
  }

  const handleOnClose = () => {
    resetState()
    if (typeof onClose === 'function') onClose()
  }

  return (
    <Modal
      data-testid="bulk-auth-modal"
      open={isOpen}
      onCancel={handleOnClose}
      size={'small'}
      title={title}
      footer={null}
      destroyOnClose
    >
      <Wrapper>
        {isError && (
          <ErrorMsg>
            <Alert showIcon type={'danger'}>
              {errorMessage}
            </Alert>
          </ErrorMsg>
        )}
        <StyledStepsWrapper>
          <Steps current={current} items={items} type="navigation" />
        </StyledStepsWrapper>
        <Formik<BulkAuthFormData> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ isValid, validateField, values, setFieldTouched }) => (
            <Form>
              <div>{steps[current].content}</div>
              <Buttons>
                <BackButton
                  visible={current > 0}
                  size="medium"
                  type="secondary"
                  data-testid="back-button"
                  onClick={prev}
                  icon={true}
                >
                  <icons.ArrowLeftSmall />
                  Back
                </BackButton>
                {hasNextStep && (
                  <Button
                    size="medium"
                    type="primary"
                    data-testid="continue-button"
                    onClick={e => {
                      if (hasReason && values.reason?.length) {
                        setFieldTouched('username', false, false)
                        setFieldTouched('password', false, false)
                        next(e)
                      } else if (!hasReason) {
                        next(e)
                      }
                    }}
                  >
                    Continue
                  </Button>
                )}
                {isFinalStep && (
                  <SubmitButton
                    size="medium"
                    loading={isLoading}
                    htmlType="submit"
                    disabled={isLoading}
                    data-testid="submit-button"
                    {...(actionType === SubscriptionMemberActions.Revoke && { danger: true })}
                  >
                    Confirm
                  </SubmitButton>
                )}
              </Buttons>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Modal>
  )
}

export default BulkAuthModal
