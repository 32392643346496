import { labelDefaultRegularStyles, labelDefaultSemiboldStyles } from '@lifebit-ai/lds'
import { Table } from 'antd'
import styled, { css } from 'styled-components'

export const AntdTableWrapper = styled.div`
  .isClickable {
    tbody tr {
      cursor: pointer;
    }
  }
  .ant-table {
    .ant-table-thead {
      background-color: ${({ theme }) => theme.color.background.highlight};

      > tr {
        background-color: transparent;
        height: 40px;

        > th {
          padding: 0 16px;
          border: none;
          background-color: transparent;

          :first-child {
            border-top-left-radius: ${({ theme }) => theme.component.table.size.cell.border.radius.header};
            border-bottom-left-radius: ${({ theme }) => theme.component.table.size.cell.border.radius.header};
          }

          :last-child {
            border-top-right-radius: ${({ theme }) => theme.component.table.size.cell.border.radius.header};
            border-bottom-right-radius: ${({ theme }) => theme.component.table.size.cell.border.radius.header};
          }

          .ant-table-column-title {
            ${labelDefaultSemiboldStyles};
          }
        }
      }
    }

    .ant-table-header {
      background-color: ${({ theme }) => theme.color.background.highlight};
    }

    .ant-table-fixed-left {
      .ant-table-thead {
        > tr {
          > th {
            :last-child {
              border-radius: 0;
            }
          }
        }
      }
    }

    .ant-table-tbody {
      border: none;

      > tr {
        height: 40px;

        :nth-child(even) {
          background-color: ${({ theme }) => theme.color.background.main};
        }

        > td {
          padding: 0 16px;
          border: none;
          ${labelDefaultRegularStyles};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // This prevents a bug where if a column is very very wide and reaches more than 100% width then it hides
          //  the table below, so we need to restrict the max width to some amount that would work in most cases.
          max-width: 300px;
        }

        &.ant-table-row-selected td {
          background-color: ${({ theme }) => theme.color.feedback.action.subtle};
        }
      }
    }
  }

  .ant-table-pagination {
    .ant-pagination-prev,
    .ant-pagination-next {
      margin: 0;
      padding: 0;
      width: 20px;
      min-width: auto;
    }

    .ant-pagination-next {
      margin-left: -6px;
    }

    .ant-pagination-item-link {
      margin: 0;
      background: transparent;
      border-radius: 0;
      border: 0;

      &:hover {
        background: transparent;
      }
    }
    .ant-pagination-item {
      border: none;
      border-radius: 0;
      min-width: 15px;
      height: 26px;
      margin: 0 6px;

      a {
        height: 26px;
      }

      &:hover {
        background: transparent;
      }
    }

    .ant-pagination-item-active {
      border: none;
      border-radius: 0;

      a {
        padding: 0;
        border-bottom: 2px solid ${({ theme }) => theme.color.feedback.action.default} !important;
      }
    }
  }
`
// Adjustments to base style like same bg for even/odd row, addition of td borders etc
export const AntdTableWrapperV2 = styled(AntdTableWrapper)`
  .ant-table {
    .ant-table-container {
      padding-bottom: ${({ theme }) => theme.size.space['05']};
      border-bottom: 1px solid ${({ theme }) => theme.color.border.default};
    }
    .ant-table-thead th.ant-table-cell:before {
      // Did not found a proper way without important
      background-color: ${({ theme }) => theme.color.background.highlight} !important;
    }
    .ant-table-tbody {
      > tr {
        :nth-child(even) {
          background-color: transparent;
        }
        > td {
          border-bottom: 1px solid ${({ theme }) => theme.color.border.divider};
        }

        &.ant-table-row-selected td {
          background-color: ${({ theme }) => theme.color.feedback.action.subtle};
        }
      }
      > tr:last-child {
        td {
          border: 0;
        }
      }
    }
  }
`

// https://github.com/ant-design/ant-design/issues/25124
export const antdScrollableTableStyles = css`
  .ant-table .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table {
    overflow-x: auto !important;

    .ant-table-row {
      cursor: pointer;
    }
  }
`

export const AntdScrollableTable = styled(Table)`
  ${antdScrollableTableStyles}
`
