import { useToast } from '@lifebit-ai/lds'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { extractErrorMessage } from 'core/utils'
import { approveResult } from 'services/results'

import useWorkspaceConfiguration from './useWorkspaceConfiguration'

const ONE_MINUTE_IN_SECONDS = 60

export interface DownloadExpiryOption {
  label: string
  timeInSeconds: number | undefined
}

export enum DownloadExpiryKeys {
  FiveMinutes,
  OneHour,
  FiveHours,
  OneDay,
  TwoDays,
  FiveDays,
  SevenDays
}

export const downloadExpiryOptions: Record<DownloadExpiryKeys, DownloadExpiryOption> = {
  [DownloadExpiryKeys.FiveMinutes]: {
    label: '5 Minutes',
    timeInSeconds: 5 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.OneHour]: {
    label: '1 Hour',
    timeInSeconds: 60 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.FiveHours]: {
    label: '5 Hours',
    timeInSeconds: 5 * 60 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.OneDay]: {
    label: '1 Day',
    timeInSeconds: 24 * 60 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.TwoDays]: {
    label: '2 Days',
    timeInSeconds: 2 * 24 * 60 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.FiveDays]: {
    label: '5 Days',
    timeInSeconds: 5 * 24 * 60 * ONE_MINUTE_IN_SECONDS
  },
  [DownloadExpiryKeys.SevenDays]: {
    label: '7 Days',
    timeInSeconds: 7 * 24 * 60 * ONE_MINUTE_IN_SECONDS
  }
}

interface UseApproveResultReturnType {
  handleApprove: (resultId: string) => void
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
  downloadExpiryOptions: Record<DownloadExpiryKeys, DownloadExpiryOption>
  selectedDownloadExpiryOptionKey: DownloadExpiryKeys
  setSelectedDownloadExpiryOptionKey: (downloadExpiryOptionKey: DownloadExpiryKeys) => void
}

interface UseApproveResultProps {
  workspaceId?: string
}

const useApproveResult = (props?: UseApproveResultProps): UseApproveResultReturnType => {
  const toast = useToast()
  const {
    data: workspaceConfiguration,
    isError: isErrorWorkspaceConfiguration,
    error: errorWorkspaceConfiguration
  } = useWorkspaceConfiguration(props?.workspaceId)
  useEffect(() => {
    if (!isErrorWorkspaceConfiguration) return
    toast({ message: extractErrorMessage(errorWorkspaceConfiguration) })
  }, [isErrorWorkspaceConfiguration, errorWorkspaceConfiguration, toast])

  const [selectedDownloadExpiryOptionKey, setSelectedDownloadExpiryOptionKey] = useState<DownloadExpiryKeys>(
    DownloadExpiryKeys.SevenDays
  )

  const workspaceDownloadExpiryOptionKey = Object.keys(downloadExpiryOptions).find(
    key =>
      downloadExpiryOptions[key as unknown as DownloadExpiryKeys].timeInSeconds ===
      workspaceConfiguration?.downloadLinkExpiresInSeconds
  ) as DownloadExpiryKeys | undefined
  useEffect(() => {
    if (!workspaceDownloadExpiryOptionKey) return
    setSelectedDownloadExpiryOptionKey(workspaceDownloadExpiryOptionKey)
  }, [workspaceDownloadExpiryOptionKey])

  const approveResultMutation = useMutation({
    mutationFn: async (resultId: string) => {
      const response = await approveResult(resultId, {
        downloadLinkExpiresInSeconds: downloadExpiryOptions[selectedDownloadExpiryOptionKey].timeInSeconds
      })
      return response
    },
    retry: false
  })

  return {
    handleApprove: approveResultMutation.mutateAsync,
    isLoading: approveResultMutation.isLoading,
    isSuccess: approveResultMutation.isSuccess,
    isError: approveResultMutation.isError,
    downloadExpiryOptions,
    selectedDownloadExpiryOptionKey,
    setSelectedDownloadExpiryOptionKey
  }
}

export default useApproveResult
