import api from 'api'

export const authenticateUser = async (
  email: string,
  password: string,
  otp?: string
): Promise<UserAuth | { otp: boolean }> => {
  const result = await api.post<UserAuth>('/auth/basic', { otp }, { auth: { username: email, password } })
  return result.data
}

export const getUserInfo = async (): Promise<UserInfo> => {
  const result = await api.get<UserInfo>('/userInfo')
  return result.data
}
