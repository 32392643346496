export const DEFAULT_PAGE_SIZE = 10
export const FIRST_PAGE = 1

export const getExportRequestName = (exportRequest: Result | undefined): string => {
  if (!exportRequest) return '--'
  if (exportRequest.name && exportRequest.name.length) return exportRequest.name

  const prefix = exportRequest.beforeCheckLocation.prefix
  const name = prefix.substring(prefix.lastIndexOf('/') + 1)
  return name.length ? name : '--'
}
