import { useQuery } from 'react-query'

import { QueryKeys } from 'services/queryKeys'
import { getFileContentBySignedUrl } from 'services/results'

export const useFileContentsQuery = (url: string) => {
  return useQuery({
    queryKey: QueryKeys.GetFileContentsFromSignedUrl(url),
    queryFn: () => {
      return getFileContentBySignedUrl(url)
    },
    enabled: !!url,
    retry: false
  })
}

export default useFileContentsQuery
