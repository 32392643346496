import React, { ReactElement } from 'react'
import { icons } from '@lifebit-ai/lds'
import moment from 'moment'

import { CurrencyCodes } from 'enums'
import { getCurrencyAmountWithSymbol } from 'utils/currency'
import { formatDuration } from 'utils/duration'
import { formatDate } from 'utils/date'

export const formatCost = (cost?: Cost): string => {
  const defaultResult = '-'
  if (!cost || isNaN(cost.amount)) return defaultResult

  const finalAmount = cost.currencyCode === CurrencyCodes.USDcents ? cost.amount / 100 : cost.amount

  return getCurrencyAmountWithSymbol(finalAmount, cost.currencyCode) || defaultResult
}

export const getAnalysisDuration = (startTime: string, endTime: string): string => {
  if (!startTime) return '-'
  const finalStartTime = moment(startTime)
  const endTimeOrCurrentTime = endTime ? moment(endTime) : moment()
  const duration = moment.duration(endTimeOrCurrentTime.diff(finalStartTime))
  const hours = Math.floor(duration.asHours())
  const mins = Math.floor(duration.asMinutes()) - hours * 60
  const sec = Math.floor(duration.asSeconds()) - hours * 60 * 60 - mins * 60
  return `${hours}h ${mins}m ${sec}s`
}

export const buildSummaryItems = (
  subscription: Subscription | undefined
): Array<{ label: string; value?: string; icon: ReactElement }> => [
  {
    label: 'Lead applicant',
    value: subscription?.requestingUser?.email,
    icon: <icons.UserSmall />
  },
  {
    label: 'Date applied',
    value: formatDate(subscription?.updatedAt),
    icon: <icons.PencilSmall />
  },
  {
    label: 'Dataset',
    value: subscription?.dataset,
    icon: <icons.DatabaseSmall />
  },
  {
    label: 'Duration',
    value: `${formatDuration(subscription?.duration || '')}, ${
      subscription?.isAutoRenewal ? 'auto-renewal' : 'no auto-renewal'
    }`,
    icon: <icons.HourglassSmall />
  },
  {
    label: 'Revenue',
    value: formatCost(subscription?.revenue),
    icon: <icons.CheckInCircleSmall />
  }
]

export const buildDocumentItems = (
  subscription: Subscription | undefined
): Array<{ label: string; icon: ReactElement; documents?: Attachment[]; application?: SubscriptionApplication }> => {
  const items: Array<{
    label: string
    icon: ReactElement
    attachments?: Attachment[]
    application?: SubscriptionApplication
  }> = []
  if (subscription?.application && Object.keys(subscription?.application).length)
    items.push({
      label: 'Application',
      icon: <icons.DocsSmall />,
      application: subscription?.application
    })
  if (subscription?.attachments?.length)
    items.push({
      label: 'Attachments',
      attachments: subscription?.attachments,
      icon: <icons.AttachSmall />
    })
  return items
}
