import { Modal, bodySmallStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`
  z-index: 99;
`
export const Wrapper = styled.div`
  padding: 0;
`

export const Content = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
  margin-bottom: ${({ theme }) => theme.size.space['07']};

  max-height: calc(100vh - 280px);
  min-height: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 25px;
`

export const ContentInner = styled.div`
  padding: 0 15px;
`

export const ButtonsWrapper = styled.footer`
  width: 100%;
  margin-top: ${({ theme }) => theme.size.space['04']};
  margin-bottom: ${({ theme }) => theme.size.space['06']};
  text-align: center;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
