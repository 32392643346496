import { AxiosError } from 'axios'
import { ResultsType } from 'enums'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ColumnsType } from 'antd/es/table'
import Search from 'components/Search/Search'
import TablePageSizeSelect from 'components/TablePageSizeSelect'
import { AntdTableWrapperV2 } from 'core/AntdTable.styles'
import { TableContainer } from 'core/Common.styles'
import useDebounce from 'hooks/useDebounce'
import {
  ActionsContainer,
  SearchContainer,
  StyledHeaderName
} from 'modules/subscriptions/analysis-results/AnalysisResults.styles'
import ResultState from 'modules/subscriptions/analysis-results/ResultState'
import { FIRST_PAGE } from 'modules/subscriptions/overview/Members'
import { DEFAULT_PAGE_SIZE, getResultsRequestedForExport } from 'services/results'

import { formatDate } from '../../utils/date'
import { StyledEmail, StyledTypedTable } from './ExportRequestsTable.styles'
import { getExportRequestName } from './utils'

interface ExportRequestsTableProps {
  resultType: ResultsType
}

const ExportRequestsTable: React.FC<ExportRequestsTableProps> = ({ resultType }) => {
  const [tableState, setTableState] = useState<{ page: number; pageSize: number }>({
    page: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [searchTerm, setSearchTerm] = useState<string>('')

  const debouncedSearchTerm = useDebounce(searchTerm, 200)

  const { data: paginatedResponse, isLoading } = useQuery<{}, AxiosError, ResultsRequestedForExportResponse>(
    [`${resultType}-export-requests`, tableState.page, tableState.pageSize, resultType, debouncedSearchTerm],
    async () => {
      return getResultsRequestedForExport(tableState.page, tableState.pageSize, resultType, debouncedSearchTerm)
    },
    {
      refetchInterval: 5000
    }
  )

  const navigate = useNavigate()

  useEffect(() => {
    setTableState({ page: FIRST_PAGE, pageSize: tableState.pageSize })
    //eslint-disable-next-line
  }, [searchTerm])

  const openResults = (resultId: string): void => {
    if (!resultId) return
    navigate(`/export-requests/view/${resultId}`)
  }

  const handleOnRow = (record: any): { onClick: () => void } => {
    return { onClick: () => openResults(record._id) }
  }

  const handleSetPageSize = (newPageSize: number): void => {
    setTableState({ page: FIRST_PAGE, pageSize: newPageSize })
  }

  const columnsForFilesAndFolders: ColumnsType<Result> = [
    {
      title: 'Status',
      render: (_text: any, data) => <ResultState state={data.status} />
    },
    {
      title: 'Name',
      render: (_text: any, data) => getExportRequestName(data)
    },
    {
      title: 'Workspace name',
      render: (_text: any, data) => data.workspaceName
    },
    {
      title: 'Workspace ID',
      render: (_text: any, data) => data.workspaceId
    },
    {
      title: 'Requested by',
      render: (_text: any, data) => <StyledEmail>{data.requestingUser?.email}</StyledEmail>
    },
    {
      title: 'Requested on',
      render: (_text: any, data) => <StyledHeaderName>{formatDate(data.createdAt)}</StyledHeaderName>
    }
  ]

  const columnsForAnalysis: ColumnsType<Result> = [
    {
      title: 'Status',
      render: (_text: any, data) => <ResultState state={data.status} />,
      key: 'status'
    },
    {
      title: 'Workspace name',
      render: (_text: any, data) => data.workspaceName,
      key: 'workspaceName'
    },
    {
      title: 'Workspace ID',
      render: (_text: any, data) => data.resourceTypeName || '-',
      key: 'workspaceId'
    },
    {
      title: 'Analysis name',
      render: (_text: any, data) => data.description,
      key: 'analysisName'
    },
    {
      title: 'Pipeline',
      render: (_text: any, data) => data.resourceTypeName || '-',
      key: 'pipeline'
    },
    {
      title: 'Requested by',
      render: (_text: any, data) => <StyledEmail>{data.requestingUser?.email}</StyledEmail>,
      key: 'requestedBy'
    }
  ]

  const columns = resultType === ResultsType.FilesFolders ? columnsForFilesAndFolders : columnsForAnalysis

  return (
    <TableContainer>
      <SearchContainer>
        <Search data-testid="search-input" handleChange={setSearchTerm} term={searchTerm} placeholder={'Search'} />
        <ActionsContainer>
          {/* TODO implement placeholder when api is ready */}
          {/*<DownloadTableButton ... */}
        </ActionsContainer>
      </SearchContainer>
      <AntdTableWrapperV2 data-testid={'export-requests-table-wrapper'} className={isLoading ? 'isLoading' : ''}>
        <StyledTypedTable
          data-testid="export-requests-table"
          columns={columns}
          dataSource={paginatedResponse?.data.map(data => ({ ...data, key: data._id }))}
          onRow={handleOnRow}
          key="_id"
          loading={isLoading}
          pagination={{
            showSizeChanger: false,
            pageSize: tableState.pageSize,
            current: tableState.page,
            total: paginatedResponse?.pagination?.totalCount || 0,
            onChange: (pageNumber: number, pageSize: number) => {
              setTableState({ page: pageSize !== tableState.pageSize ? FIRST_PAGE : pageNumber, pageSize })
            }
          }}
        />
        <TablePageSizeSelect
          total={paginatedResponse?.pagination?.totalCount || 0}
          pageSize={tableState.pageSize}
          setPageSize={handleSetPageSize}
        />
      </AntdTableWrapperV2>
    </TableContainer>
  )
}

export default ExportRequestsTable
