import { CurrencyCodes } from 'enums'

/**
 * Determines the currency symbol for a given currency code.
 */
export const getCurrencySymbol = (currencyCode: CurrencyCodes): string => {
  const currency: { [index: string]: string } = {
    [CurrencyCodes.USD]: '$',
    [CurrencyCodes.USDcents]: '$',
    [CurrencyCodes.GBP]: '£'
  }

  return currency[currencyCode]
}

/**
 * Get a representation of the currency amount parsed along with its corresponding currency code.
 */
export const getCurrencyAmountWithSymbol = (amount: number, currencyCode: CurrencyCodes): string | null => {
  const currencySymbol = getCurrencySymbol(currencyCode)
  if (!currencySymbol) return null

  return `${currencySymbol}${amount.toFixed(2)}`
}
