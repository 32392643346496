import axios from 'axios'
import { LoginTypes } from 'enums'
import React, { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { Navigate, useNavigate } from 'react-router-dom'

import { AuthContext } from 'auth/AuthContext'
import AppLoadingSpinner from 'core/AppLoadingSpinner'
import useQueryParams from 'hooks/useQueryParams'
import { validateMagicLink } from 'services/auth'
import { QueryKeys } from 'services/queryKeys'

import { USER_SESSION_LOCAL_STORAGE_KEY } from '../../auth/airlockBe'
import { removeStorageValue } from '../../core/local-storage'

export enum MagicLinkErrorReason {
  Expired = 'Expired',
  Unknown = 'Unknown'
}

const LoginMagicLinkCallback: React.FC = () => {
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const token = queryParams.get('token') as string
  const context = useContext(AuthContext)

  const query = useQuery({
    queryKey: QueryKeys.GetValidateMagicLink(token),
    queryFn: async () => validateMagicLink({ token }),
    enabled: !!token,
    retry: false
  })

  useEffect(() => {
    if (query.error || query.isLoading || !query.data?.token) return

    removeStorageValue(USER_SESSION_LOCAL_STORAGE_KEY)

    context.autoLogin(query.data)

    navigate('/')
    //eslint-disable-next-line
  }, [query.error, query.isLoading, query.data?.token])

  if (query.isLoading) return <AppLoadingSpinner />

  if (query.error) {
    if (axios.isAxiosError(query.error) && query.error.response?.status === 401) {
      removeStorageValue(USER_SESSION_LOCAL_STORAGE_KEY)
      return <Navigate replace to={`/login?type=${LoginTypes.MagicLink}&error=${MagicLinkErrorReason.Expired}`} />
    }
    return <Navigate replace to={`/login?type=${LoginTypes.MagicLink}&error=${MagicLinkErrorReason.Unknown}`} />
  }

  return <AppLoadingSpinner />
}

export default LoginMagicLinkCallback
