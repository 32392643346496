import React from 'react'
import { useParams } from 'react-router-dom'
import { Tabs } from 'antd'
import { Loader } from '@lifebit-ai/lds'

import { SubscriptionTabViews } from 'enums'
import useSubscription from 'hooks/useSubscription'
import { CommonPageWrapper } from 'core/Common.styles'
import SubscriptionStatusIcon from 'modules/subscriptions/subscriptions-table/SubscriptionStatusIcon'

import SubscriptionOverview from './overview/SubscriptionOverview'
import AnalysisResultsTable from './analysis-results/AnalysisResultsTable'
// import CohortsTable from './cohorts/CohortsTable'
import { TitleWrapper, Organisation, Separator, Dataset, LoaderWrapper, ErrorWrapper } from './Subscription.styles'

const Subscription: React.FC = () => {
  const { subscriptionId } = useParams()
  const { data: subscription, isLoading, isError } = useSubscription(subscriptionId || '')

  if (!subscriptionId) return null

  if (isLoading)
    return (
      <LoaderWrapper data-testid={'subscription-loader'}>
        <Loader text={false} />
      </LoaderWrapper>
    )

  if (isError) {
    return <ErrorWrapper>Error loading subscription.</ErrorWrapper>
  }

  if (!subscription || Object.keys(subscription).length === 0) {
    return <ErrorWrapper>Subscription does not contain data.</ErrorWrapper>
  }

  return (
    <CommonPageWrapper>
      <TitleWrapper data-testid="subscription-title">
        <SubscriptionStatusIcon status={subscription.status} size="medium" />
        <Organisation>{subscription.organization}</Organisation>
        <Separator>/</Separator>
        <Dataset>{subscription.dataset}</Dataset>
      </TitleWrapper>
      <Tabs>
        <Tabs.TabPane tab={SubscriptionTabViews.Overview} key={SubscriptionTabViews.Overview}>
          <SubscriptionOverview subscriptionId={subscriptionId} />
        </Tabs.TabPane>
        {/* TODO uncomment this when api is ready */}
        {/*<Tabs.TabPane tab={SubscriptionTabViews.Cohorts} key={SubscriptionTabViews.Cohorts}>*/}
        {/*  <CohortsTable subscriptionId={subscriptionId} subscriptionStatus={subscription.status} />*/}
        {/*</Tabs.TabPane>*/}
        <Tabs.TabPane tab={SubscriptionTabViews.Analysis} key={SubscriptionTabViews.Analysis}>
          <AnalysisResultsTable subscriptionId={subscriptionId} subscriptionStatus={subscription.status} />
        </Tabs.TabPane>
      </Tabs>
    </CommonPageWrapper>
  )
}

export default Subscription
