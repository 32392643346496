import { SubscriptionStatuses } from 'enums'
import styled, { DefaultTheme } from 'styled-components'

const getStatusIconColor = (status: SubscriptionStatuses, theme: DefaultTheme) => {
  switch (status) {
    case SubscriptionStatuses.Approved:
      return theme.color.feedback.success.default
    case SubscriptionStatuses.Pending:
      return theme.color.feedback.warning.default
    case SubscriptionStatuses.Rejected:
      return theme.color.feedback.danger.default
    case SubscriptionStatuses.ReSubmitted:
      return theme.color.content.secondary
    default:
      return theme.color.content.main
  }
}

export const StatusIconWrapper = styled.div<{ status: SubscriptionStatuses }>`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ status, theme }) => getStatusIconColor(status, theme)};
    }
  }
`
