import styled from 'styled-components'

interface PopoverContainerProps {
  visible?: boolean
  zIndex?: number
}

export const PopoverContainer = styled.div<PopoverContainerProps>`
  background-color: ${({ theme }) => theme.color.background.main};
  border-radius: ${({ theme }) => theme.size.border.weight['02']};
  font-size: ${({ theme }) => theme.font.label.fontSize.default};
  z-index: 1000;
  ${({ visible }) => !visible && 'visibility: hidden;'}
  font-weight: ${({ theme }) => theme.font.body.fontWeight.small};
  line-height: 135%;
`

export const TooltipArrow = styled.div<{ backgroundColor?: string }>`
  [data-popper-placement^='bottom'] > & {
    top: -4px;
    ::after {
      border-left: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      border-top: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
    }
  }

  [data-popper-placement^='top'] > & {
    bottom: -4px;
    ::after {
      border-right: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      border-bottom: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
    }
  }
  [data-popper-placement^='right'] > & {
    left: -4px;
    ::after {
      border-left: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      border-bottom: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
    }
  }
  [data-popper-placement^='left'] > & {
    right: -4px;
    ::after {
      border-right: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      border-top: 1px solid ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
    }
  }

  [data-popper-placement^='bottom'] > & {
    ::after {
      border-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.background.contrast};
    }
  }

  [data-popper-placement^='top'] > & {
    ::after {
      border-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.background.contrast};
    }
  }

  [data-popper-placement^='right'] > & {
    ::after {
      border-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.background.contrast};
    }
  }

  [data-popper-placement^='left'] > & {
    ::after {
      border-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.base.neutral.black};
      background-color: ${({ theme, backgroundColor }) => backgroundColor ?? theme.color.background.contrast};
    }
  }

  width: ${({ theme }) => theme.size['03']};
  height: ${({ theme }) => theme.size['03']};
  z-index: -1;

  ::after {
    content: '';
    transform: rotate(45deg);
    width: 7px;
    height: 7px;
    z-index: -1;
    position: absolute;
  }
`

export const ReferenceElementContainer = styled.div`
  display: flex;
  align-items: center;
`
