export const extractErrorMessage = (error?: any | null, defaultMessage?: string): string | undefined => {
  if (!error) return undefined

  if (error.response?.data?.message) {
    return error.response?.data.message as string
  }

  if (typeof error.response?.data === 'string') {
    return error.response?.data
  }

  return defaultMessage || 'Unknown error.'
}
