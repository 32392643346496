import React from 'react'

import { SubscriptionStatuses } from 'enums'

import SubscriptionStatusIcon from './SubscriptionStatusIcon'
import { StatusWrapper } from './SubscriptionStatus.styles'

interface SubscriptionStatusProps {
  text: string
  status: SubscriptionStatuses
}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = ({ text, status }) => {
  return (
    <StatusWrapper>
      <SubscriptionStatusIcon status={status} />
      {text}
    </StatusWrapper>
  )
}

export default SubscriptionStatus
