import React, { useState, useEffect } from 'react'
import { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { ResultsState, SubscriptionStatuses } from 'enums'
import { getResultsBySubscriptionId } from 'services/results'
import useDebounce from 'hooks/useDebounce'
import TablePageSizeSelect from 'components/TablePageSizeSelect'
import Search from 'components/Search/Search'
// import DownloadTableButton from 'components/DownloadTableButton'
import { TableContainer } from 'core/Common.styles'
import { AntdScrollableTable, AntdTableWrapperV2 } from 'core/AntdTable.styles'

import ResultState from './ResultState'
import ConfirmReviewModal from './ConfirmReviewModal'
import { ActionsContainer, SearchContainer, StyledHeaderName, PlaceholderMessage } from './AnalysisResults.styles'
import { formatCost, getAnalysisDuration } from '../overview/helpers'

export const DEFAULT_PAGE_SIZE = 10
export const FIRST_PAGE = 1

interface AnalysisResultsTableProps {
  subscriptionId: string
  subscriptionStatus: SubscriptionStatuses
}

const AnalysisResultsTable: React.FC<AnalysisResultsTableProps> = ({ subscriptionId, subscriptionStatus }) => {
  const [tableState, setTableState] = useState<{ page: number; pageSize: number }>({
    page: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isConfirmReviewModalOpen, setIsConfirmReviewModalOpen] = useState<boolean>(false)
  const [resultToReview, setResultToReview] = useState<Result | null>(null)

  const debouncedSearchTerm = useDebounce(searchTerm, 200)
  const { data: resultsPaginatedResponse, isLoading } = useQuery<{}, AxiosError, SubscriptionResultsResponse>(
    ['subscription-results', subscriptionId, tableState.page, tableState.pageSize, debouncedSearchTerm],
    async () => getResultsBySubscriptionId(subscriptionId, tableState.page, tableState.pageSize, debouncedSearchTerm),
    {
      enabled: subscriptionStatus === SubscriptionStatuses.Approved,
      refetchInterval: 5000
    }
  )

  const navigate = useNavigate()

  useEffect(() => {
    setTableState({ page: FIRST_PAGE, pageSize: tableState.pageSize })
    //eslint-disable-next-line
  }, [searchTerm])

  const openResults = (resultId: string) => {
    navigate(`/export-requests/view/${resultId}`)
  }

  const onAcceptReview = (result: Result) => {
    openResults(result?._id)
  }

  const columns = [
    {
      title: 'Status',
      render: (text: any, data: any) => <ResultState state={data.status} subscriptionMode={true} />
    },
    {
      title: 'Analysis name',
      render: (text: any, data: any) => data.description
    },
    {
      title: 'Pipeline name',
      render: (text: any, data: any) => data.resourceTypeName || '-'
    },
    {
      title: 'Created by',
      render: (text: any, data: any) => <StyledHeaderName>{data.requestingUser.email}</StyledHeaderName>
    },
    {
      title: 'Run time',
      render: (text: string, data: any) => getAnalysisDuration(data.startTime, data.endTime)
    },
    {
      title: 'Cost',
      render: (text: any, data: any) => formatCost(data.cost?.finalCost)
    }
  ]

  const handleOnRow = (record: any) => {
    return {
      onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (record.status === ResultsState.PendingVerification) {
          // For pending results, user is redirected to data-export after he accepts review
          setIsConfirmReviewModalOpen(true)
          setResultToReview(record)
        } else {
          openResults(record._id)
        }
      }
    }
  }

  const handleSetPageSize = (newPageSize: number) => {
    setTableState({ page: FIRST_PAGE, pageSize: newPageSize })
  }

  if (subscriptionStatus !== SubscriptionStatuses.Approved)
    return <PlaceholderMessage>Analysis results are not available for a not approved subscription</PlaceholderMessage>

  return (
    <>
      <TableContainer>
        <SearchContainer>
          <Search
            data-testid="results-search-input"
            handleChange={setSearchTerm}
            term={searchTerm}
            placeholder={'Search'}
          />
          <ActionsContainer>
            {/* TODO implement this placeholder when api is ready */}
            {/*<DownloadTableButton ... */}
          </ActionsContainer>
        </SearchContainer>
        <AntdTableWrapperV2 data-testid={'results-table-wrapper'} className={isLoading ? 'isLoading' : ''}>
          <AntdScrollableTable
            data-testid="results-table"
            columns={columns}
            dataSource={resultsPaginatedResponse?.data}
            onRow={handleOnRow}
            loading={isLoading}
            pagination={{
              showSizeChanger: false,
              pageSize: tableState.pageSize,
              current: tableState.page,
              total: resultsPaginatedResponse?.pagination?.totalCount || 0,
              onChange: (pageNumber: number, pageSize: number) => {
                setTableState({ page: pageSize !== tableState.pageSize ? FIRST_PAGE : pageNumber, pageSize })
              }
            }}
          />
          <TablePageSizeSelect
            total={resultsPaginatedResponse?.pagination?.totalCount || 0}
            pageSize={tableState.pageSize}
            setPageSize={handleSetPageSize}
          />
        </AntdTableWrapperV2>
      </TableContainer>
      {isConfirmReviewModalOpen && (
        <ConfirmReviewModal
          result={resultToReview as Result}
          onClose={() => {
            setIsConfirmReviewModalOpen(false)
            setResultToReview(null)
          }}
          action={onAcceptReview}
          isOpen={isConfirmReviewModalOpen}
        />
      )}
    </>
  )
}

export default AnalysisResultsTable
