import { useQuery } from 'react-query'

import { getWorkspaceConfiguration } from 'services/workspaces'
import { QueryKeys } from 'services/workspaces/queryKeys'

const useWorkspaceConfiguration = (workspaceId?: string) => {
  return useQuery({
    queryKey: QueryKeys.GetWorkspaceConfiguration(workspaceId),
    queryFn: ({ signal }) => {
      if (!workspaceId) return
      return getWorkspaceConfiguration(workspaceId, signal)
    },
    enabled: !!workspaceId,
    retry: false,
    refetchOnMount: false
  })
}

export default useWorkspaceConfiguration
