import api from 'api'

// TODO remove mock
// import { getMockSubscriptionMembersResponse } from './testSetup/subscriptions'

export const getSubscriptions = async (
  searchTerm?: string,
  page: number = 0,
  pageSize: number = 20
): Promise<SubscriptionsResponse> => {
  return (
    await api.get<SubscriptionsResponse>(`/subscriptions`, {
      params: {
        page,
        perPage: pageSize,
        ...(searchTerm ? { search: searchTerm } : {})
      }
    })
  ).data
}

export const getSubscription = async (id: string): Promise<Subscription | undefined> => {
  return (await api.get<Subscription>(`/subscriptions/${id}`)).data
}

// NOTE: this became unused, instead we get the members directly from the subscription endpoint.
// In the future we may reuse this endpoint.
export const getSubscriptionMembers = async (
  id: string,
  page: number = 1,
  pageSize: number = 20
): Promise<SubscriptionMembersResponse> => {
  return (await api.get<SubscriptionMembersResponse>(`/subscriptions/${id}/subscribers`)).data

  // return getMockSubscriptionMembersResponse()
}

export const rejectSubscription = async (subscriptionId: string, reason: string): Promise<void> => {
  return (await api.post(`/subscriptions/${subscriptionId}/reject`, { reason })).data
}

export const approveSubscription = async (subscriptionId?: string): Promise<void> => {
  return (await api.post(`/subscriptions/${subscriptionId}/approve`)).data
}

export const removeSubscriptionMember = async (
  subscriptionId: string,
  memberEmail: string,
  managerUsername: string,
  managerPassword: string
) => {
  return (
    await api.post(`/subscriptions/${subscriptionId}/subscribers/remove`, {
      email: memberEmail,
      auth: {
        username: managerUsername,
        password: managerPassword
      }
    })
  ).data
}

export const approveSubscriptionMembers = async (
  subscriptionId: string,
  emailsOfTheSubscribers: string[],
  managerUsername: string,
  managerPassword: string
): Promise<ApproveSubscriptionsMembersResponse> => {
  return (
    await api.post<ApproveSubscriptionsMembersResponse>(`/subscriptions/${subscriptionId}/subscribers/approve`, {
      subscribers: emailsOfTheSubscribers,
      auth: {
        username: managerUsername,
        password: managerPassword
      }
    })
  ).data
}

// TODO fix this when api is ready
export const getAttachmentSignedUrl = async (subscriptionId: string, fileKey: string): Promise<SignedUrlResponse> => {
  return { signedUrl: '', fileKey }
}
