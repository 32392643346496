import { LdsToastContainer, globals, themes } from '@lifebit-ai/lds'
import '@lifebit-ai/lds/dist/index.css'
import React, { useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import DownloadPage from 'modules/download/DownloadPage'

import AirlockRoutes from './AirlockRoutes'
import AppContextProvider from './AppContextProvider'
import { AppGlobalStyle } from './GlobalStyles'
import AuthContextProvider from './auth/AuthContextProvider'
import Envs, { castToStringOrUseDefault } from './env'
import AppContainer from './modules/general/app-container/AppContainer'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})
const LdsGlobalStyle = globals.GlobalStyle

export const GENERIC_PAGE_TITLE = 'Lifebit Airlock'

const App: React.FC = () => {
  const theme = themes.Light
  const baseUrl = castToStringOrUseDefault(Envs.AIRLOCK_FE_BASE_URL, '/')

  const [isSubscriptionManagementAvailable, setIsSubscriptionManagementAvailable] = useState<boolean>(false)

  return (
    <AppContextProvider
      isSubscriptionManagementAvailable={isSubscriptionManagementAvailable}
      setIsSubscriptionManagementAvailable={setIsSubscriptionManagementAvailable}
    >
      <ThemeProvider theme={theme}>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter basename={baseUrl}>
              <Routes>
                <Route path="/download/:resultId" element={<DownloadPage />} />

                <Route
                  path="*"
                  element={
                    <AuthContextProvider>
                      <AppGlobalStyle />
                      <LdsGlobalStyle />
                      <LdsToastContainer />
                      <AppContainer>
                        <Helmet>
                          <meta charSet="utf-8" />
                          <title>{GENERIC_PAGE_TITLE}</title>
                        </Helmet>
                        <AirlockRoutes />
                      </AppContainer>
                    </AuthContextProvider>
                  }
                />
              </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </CookiesProvider>
      </ThemeProvider>
    </AppContextProvider>
  )
}

export default App
