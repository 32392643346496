import React, { ReactNode } from 'react'
import { StyledH1 } from './H1.styles'

interface H1Props {
  children: ReactNode
  className?: string
}

export const H1: React.FC<H1Props> = ({ children, className }) => {
  return <StyledH1 className={className}>{children}</StyledH1>
}
