import React from 'react'
import { Button } from '@lifebit-ai/lds'
import { StyledModal, Wrapper, Description, Buttons } from 'components/ApprovalModal/ApprovalModal.styles'

interface ConfirmReviewModalProps {
  result: Result
  isOpen?: boolean
  onClose: () => void
  action: (result: Result) => void
}

const ConfirmReviewModal: React.FC<ConfirmReviewModalProps> = ({ result, isOpen, onClose, action }) => {
  return (
    <StyledModal
      data-testid="review-confirm-modal"
      open={isOpen}
      onCancel={onClose}
      size={'small'}
      footer={null}
      title="Review analysis results?"
      destroyOnClose
    >
      <Wrapper>
        <Description>
          The results of this analysis have not been approved for release to the user. Do you want to review this?
          request
        </Description>

        <Buttons>
          <Button size={'large'} type={'secondary'} onClick={onClose} data-testid={'cancel-approve-button'}>
            Cancel
          </Button>
          <Button size={'large'} type={'primary'} onClick={() => action(result)} data-testid={'review-button'}>
            Review
          </Button>
        </Buttons>
      </Wrapper>
    </StyledModal>
  )
}

export default ConfirmReviewModal
