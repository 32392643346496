import api from 'api'

import { WorkspaceConfiguration } from './types'

export const getWorkspaceConfiguration = async (
  workspaceId: string,
  signal?: AbortSignal
): Promise<WorkspaceConfiguration> => {
  const { data } = await api.get<WorkspaceConfiguration>(`/workspaces/${workspaceId}/configuration`, { signal })
  return data
}
