import React from 'react'
import { Button } from '@lifebit-ai/lds'

import { StyledModal, Wrapper, Content, Buttons, ContentInner } from './ApplicationModal.styles'

interface ApplicationModalProps {
  onClose: () => void
  title?: string
  content?: React.ReactElement | string
  isOpen?: boolean
}

const ApplicationModal: React.FC<ApplicationModalProps> = ({
  title = 'Application form',
  onClose,
  content,

  isOpen
}) => {
  return (
    <StyledModal
      data-testid="application-modal"
      open={isOpen}
      onCancel={onClose}
      size={'large'}
      footer={null}
      title={title}
      destroyOnClose
    >
      <Wrapper>
        <Content>
          <ContentInner>{content}</ContentInner>
        </Content>
        <Buttons>
          <Button size={'large'} type={'secondary'} onClick={onClose}>
            Close
          </Button>
        </Buttons>
      </Wrapper>
    </StyledModal>
  )
}

export default ApplicationModal
