import { Label, Select } from '@lifebit-ai/lds'
import React from 'react'

import { DownloadExpiryKeys, DownloadExpiryOption } from '../hooks/useApproveResult'
import * as styles from './DownloadExpirySelector.styles'

interface DownloadExpirySelectorProps {
  options: Record<DownloadExpiryKeys, DownloadExpiryOption>
  value: string
  selectedDownloadExpiryOptionKey: DownloadExpiryKeys
  onChange: (downloadExpiryOptionKey: DownloadExpiryKeys) => void
}

const DownloadExpirySelector: React.FC<DownloadExpirySelectorProps> = ({
  options,
  value,
  selectedDownloadExpiryOptionKey,
  onChange
}) => {
  return (
    <styles.Container>
      <Label helpText="The amount of time before a download link expires.">Download expiry</Label>
      <Select
        value={value}
        options={Object.entries(options).map(([key, option]) => {
          return {
            value: key,
            label: option.label
          }
        })}
        onChange={value => {
          onChange(parseInt(value))
        }}
      />
    </styles.Container>
  )
}

export default DownloadExpirySelector
