// NOTE #1: This is the single entry point where we check env vars to define the app mode and set accordingly the context.
// NOTE #2: at the moment the only way to check if the airlock manager manages subscriptions is to query the
// subscriptions endpoint and check if there are any results.
import { AxiosError } from 'axios'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'

import { getSubscriptions } from 'services/subscriptions'

import { AppContext } from './AppContext'
import Env, { castToBoolOrUseDefault } from './env'

const AppMode: React.FC = () => {
  const isSubscriptionsEnabled = castToBoolOrUseDefault(Env.AIRLOCK_SUBSCRIPTIONS_ENABLED, true)
  const { setIsSubscriptionManagementAvailable } = useContext(AppContext)

  const { data: paginatedResponse, isLoading } = useQuery<{}, AxiosError, SubscriptionsResponse>(
    ['subscriptions'],
    async () => {
      return getSubscriptions()
    },
    {
      retry: false,
      enabled: isSubscriptionsEnabled
    }
  )

  if (isLoading) return null

  const hasSubscriptions = paginatedResponse?.data?.length

  if (isSubscriptionsEnabled && hasSubscriptions) setIsSubscriptionManagementAvailable(true)

  return null
}

export default AppMode
