enum Envs {
  AIRLOCK_BE_URL = 'REACT_APP_AIRLOCK_BE_URL',
  AIRLOCK_BE_PREFIX = 'REACT_APP_AIRLOCK_BE_PREFIX',
  API_BE_URL = 'REACT_APP_API_BE_URL',
  API_BE_PREFIX = 'REACT_APP_API_BE_PREFIX',
  AIRLOCK_FE_BASE_URL = 'REACT_APP_AIRLOCK_FE_BASE_URL',
  AIRLOCK_HELPDESK_URL = 'REACT_APP_AIRLOCK_HELPDESK_URL',
  AIRLOCK_SUBSCRIPTIONS_ENABLED = 'REACT_APP_AIRLOCK_SUBSCRIPTIONS_ENABLED'
}

export const getEnv = (envName: string): string | undefined => {
  // @ts-ignore
  return (window._env_ && window._env_[envName]) || process.env[envName]
}

export const castToStringOrUseDefault = (envVariableName: Envs, defaultValue: string): string => {
  const envValue = getEnv(envVariableName)
  return !envValue || getEnv(envVariableName) === 'None' ? defaultValue : envValue
}

export const castToBoolOrUseDefault = (envVariableName: Envs, defaultValue: boolean): boolean => {
  if (['None', '', undefined].includes(getEnv(envVariableName))) return defaultValue
  return getEnv(envVariableName) === 'true'
}

export const getAirlockBeUrl = (): string => {
  const url = castToStringOrUseDefault(Envs.AIRLOCK_BE_URL, window.location.origin)
  const prefix = castToStringOrUseDefault(Envs.AIRLOCK_BE_PREFIX, '')
  return `${url}${prefix}`
}

export const getApiServerBeUrl = (): string => {
  const url = castToStringOrUseDefault(Envs.API_BE_URL, window.location.origin.replace('airlock.', ''))
  const prefix = castToStringOrUseDefault(Envs.API_BE_PREFIX, '/api/v1')
  return `${url}${prefix}`
}

export default Envs
