import styled from 'styled-components'

export const AppContentWithNavbar = styled.div`
  width: 100%;
  min-height: 100vh;
  flex-grow: 1;
  overflow-y: scroll;
`

export const StyledAppContainer = styled.div`
  display: flex;
  height: 100%;
  background: ${({ theme }) => theme.color.background.main};
`
