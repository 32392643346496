import { h1Styles, labelDefaultRegularStyles } from '@lifebit-ai/lds'
import styled from 'styled-components'

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0 auto ${({ theme }) => theme.size.space['06']};
  position: relative;
  width: 48px;
`

export const LogoOverlay = styled.div<{ success?: boolean }>`
  align-items: center;
  background: ${({ theme, success }) => (success ? theme.color.feedback.success.default : '#c58700')};
  border-radius: 49px;
  border: 2px solid #fff;
  bottom: -5px;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.size.space['01']};
  position: absolute;
  right: -6.5px;

  svg {
    height: ${({ theme }) => theme.size.icon.small};
    width: ${({ theme }) => theme.size.icon.small};

    path {
      fill: ${({ theme }) => theme.color.content.contrast};
    }
  }
`

export const Title = styled.div`
  ${h1Styles};
  color: ${({ theme }) => theme.color.content.main};
  text-align: center;
`

export const Description = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
  text-align: center;
  margin-top: ${({ theme }) => theme.size.space['03']};
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`
