import styled from 'styled-components'
import { Modal, Button, Input, bodySmallStyles } from '@lifebit-ai/lds'
import { Form } from 'formik'

export const StyledModal = styled(Modal)`
  .lds-ant-modal {
    max-width: 375px;
  }
`

export const Wrapper = styled.div`
  padding: 0;
`

export const Description = styled.div`
  ${bodySmallStyles};
  text-align: left;
  margin-top: ${({ theme }) => theme.size.space['06']};
  margin-bottom: ${({ theme }) => theme.size.space['07']};
  color: ${({ theme }) => theme.color.content.main};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button:last-child {
    margin-left: ${({ theme }) => theme.size.space['04']};
  }
`

export const SubmitButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.feedback.danger.default};
`

export const ErrorMsg = styled.div`
  margin-top: ${({ theme }) => theme.size.space['05']};
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

export const StyledInput = styled(Input)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size.space['08']};
`

export const StyledForm = styled(Form)`
  display: block;
`
