import React, { ReactElement } from 'react'
import { icons } from '@lifebit-ai/lds'

import { ResultsState } from 'enums'

import { StatusIconWrapper, StatusWrapper } from './Results.styles'

type Props = {
  status: ResultsState
}

const getResultStatusIcon = (status: ResultsState): ReactElement | null => {
  switch (status) {
    case ResultsState.Complete:
      return <icons.CheckInCircleSmall data-testid="result-complete-icon" />
    case ResultsState.PendingVerification:
      return <icons.ClockSmall data-testid="result-pending-icon" />
    case ResultsState.Rejected:
      return <icons.CloseInCircleSmall data-testid="result-rejected-icon" />
    default:
      return null
  }
}

const getStatusText = (status: ResultsState): string => {
  switch (status) {
    case ResultsState.Complete:
      return 'Approved'
    case ResultsState.PendingVerification:
      return 'Pending'
    case ResultsState.Rejected:
      return 'Rejected'
    default:
      return 'Invalid status'
  }
}

const ResultStatusTag = ({ status }: Props) => {
  const icon = getResultStatusIcon(status)
  const text = getStatusText(status)
  if (!icon) return null
  return (
    <StatusWrapper>
      <StatusIconWrapper status={status}>{icon}</StatusIconWrapper>
      {text}
    </StatusWrapper>
  )
}

export default ResultStatusTag
