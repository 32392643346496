import styled from 'styled-components'
import { Table } from 'antd'
import { ResponsiveBreakpoints } from 'enums'

export const StyledTable = styled(Table)`
  .ant-table .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  @media (max-width: ${ResponsiveBreakpoints.Medium - 1}px) {
    .ant-table {
      overflow-x: scroll;
    }
  }
`

export const RowMenuTrigger = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.content.main};
`
