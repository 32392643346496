import React from 'react'

import { LifebitLogo, LinkedinLogo, XLogo } from 'img'

import * as styles from './AuthWrapper.styles'

const LifebitPageLinks = {
  Whitepapers: {
    link: 'https://www.lifebit.ai/white-papers',
    label: 'Whitepapers'
  },
  Careers: {
    link: 'https://www.lifebit.ai/careers/',
    label: 'Careers'
  },
  ContactUs: {
    link: 'https://www.lifebit.ai/contact-us/',
    label: 'Contact us'
  }
} as const

const LifebitSocialLinks = {
  Whitepapers: {
    link: 'https://www.linkedin.com/company/lifebit-ai/mycompany/',
    label: 'Linkedin',
    logo: <LinkedinLogo />
  },
  Careers: {
    link: 'https://twitter.com/lifebitAI',
    label: 'Twitter',
    logo: <XLogo />
  }
} as const

interface AuthWrapperProps {
  children: React.ReactNode
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
  return (
    <styles.AuthWrapperContainer>
      <styles.Wrapper>
        <styles.LogoContainer>
          <LifebitLogo />
        </styles.LogoContainer>
        <styles.FormWrapper>
          <styles.ChildrenWrapper>{children}</styles.ChildrenWrapper>
        </styles.FormWrapper>
      </styles.Wrapper>

      <styles.Footer>
        <styles.FooterTop>
          {Object.values(LifebitPageLinks).map(({ link, label }) => {
            return (
              <styles.FooterTopElement key={link} href={link} target="_blank" rel="noreferrer">
                {label}
              </styles.FooterTopElement>
            )
          })}
        </styles.FooterTop>
        <styles.FooterBottom>
          <styles.FooterBottomSocial>
            {Object.values(LifebitSocialLinks).map(({ link, logo }) => {
              return (
                <a key={link} href={link} target="_blank" rel="noreferrer">
                  {logo}
                </a>
              )
            })}
          </styles.FooterBottomSocial>
          <styles.FooterBottomRights>
            © {new Date().getFullYear()} Lifebit. All rights reserved
          </styles.FooterBottomRights>
        </styles.FooterBottom>
      </styles.Footer>
    </styles.AuthWrapperContainer>
  )
}

export default AuthWrapper
