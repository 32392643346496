import React from 'react'
import { icons } from '@lifebit-ai/lds'

import { ResultsState } from 'enums'

import { StateWrapper, StateText, StatusIconWrapper } from './AnalysisResults.styles'

type Props = {
  state: string
  subscriptionMode?: boolean
}

const ResultState = ({ state, subscriptionMode }: Props) => {
  switch (state) {
    case ResultsState.Complete:
      return (
        <StateWrapper>
          <StatusIconWrapper status={state}>
            <icons.CheckInCircleSmall data-testid="approved-icon" />
          </StatusIconWrapper>
          <StateText>Approved</StateText>
        </StateWrapper>
      )
    case ResultsState.PendingVerification:
      return (
        <StateWrapper>
          <StatusIconWrapper status={state}>
            <icons.ClockSmall data-testid="pending-icon" />
          </StatusIconWrapper>
          <StateText>{subscriptionMode ? 'Review needed' : 'Pending'}</StateText>
        </StateWrapper>
      )
    case ResultsState.Rejected:
      return (
        <StateWrapper>
          <StatusIconWrapper status={state}>
            <icons.CloseInCircleSmall data-testid="rejected-icon" />
          </StatusIconWrapper>
          <StateText>Rejected</StateText>
        </StateWrapper>
      )
    default:
      return null
  }
}

export default ResultState
