import styled from 'styled-components'

type LoadingContainerProps = {
  transparent?: boolean
}

export const LoadingContainer = styled.div<LoadingContainerProps>`
  position: fixed;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: ${props => (props.transparent ? 'rgba(255, 255, 255, 0.6) !important;' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`
