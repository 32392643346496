import React from 'react'
import { Alert } from '@lifebit-ai/lds'

import { SubscriptionStatuses } from 'enums'

import { AlertTitle } from './SubscriptionOverview.styles'
import { formatDate } from 'utils/date'

interface NotificationsProps {
  subscription: Subscription
}

export const Notifications: React.FC<NotificationsProps> = ({ subscription }) => {
  const handleDismissApprovedAlert = () => {
    // TODO/TBD
  }

  return (
    <>
      {subscription?.status === SubscriptionStatuses.Rejected ? (
        <Alert data-testid="subscription-status-alert" type="danger" title={<AlertTitle>Request rejected</AlertTitle>}>
          {`The subscription request was rejected on ${formatDate(subscription?.rejectedAt)}, with a note: ${
            subscription?.rejectionReason
          }`}
        </Alert>
      ) : null}

      {subscription?.status === SubscriptionStatuses.Approved ? (
        <Alert
          data-testid="subscription-status-alert"
          type="success"
          title={<AlertTitle>Request approved</AlertTitle>}
          showIcon={true}
          onDismiss={handleDismissApprovedAlert}
        >
          We will now add the subscription members to their workspace and inform you, so you can grant them data access.
        </Alert>
      ) : null}
    </>
  )
}
