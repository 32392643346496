import styled from 'styled-components'
import { Modal, Button, bodySmallStyles } from '@lifebit-ai/lds'

export const StyledModal = styled(Modal)`
  .lds-ant-modal {
    max-width: 375px;
  }
`

export const Wrapper = styled.div`
  padding: 0;
`

export const Content = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
  margin-bottom: ${({ theme }) => theme.size.space['07']};
`

export const Description = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.main};
  margin-bottom: ${({ theme }) => theme.size.space['06']};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  button:last-child {
    margin-left: ${({ theme }) => theme.size.space['04']};
  }
`

export const SubmitButton = styled(Button)`
  margin-left: ${({ theme }) => theme.size.space['06']};
`

export const ErrorMsg = styled.div`
  margin-top: ${({ theme }) => theme.size.space['05']};
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`
