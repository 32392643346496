import styled from 'styled-components'
import { bodySmallStyles, labelDefaultSemiboldStyles, Button, h4Styles } from '@lifebit-ai/lds'
import { CommonContainer } from 'core/Common.styles'
import { ResponsiveBreakpoints } from 'enums'

export const MembersTableContainer = styled(CommonContainer)`
  margin-top: ${({ theme }) => theme.size.space['05']};
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${ResponsiveBreakpoints.Medium - 1}px) {
    flex-direction: column;
  }
`

export const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

export const SummaryAndDocuments = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${ResponsiveBreakpoints.Large - 1}px) {
    flex-direction: column;
  }
`

export const StyledSummary = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.size.space['11']};
`

export const StyledDocuments = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ theme }) => theme.size.space['11']};
  @media (max-width: ${ResponsiveBreakpoints.Large - 1}px) {
    margin-top: ${({ theme }) => theme.size.space['09']};
  }
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  @media (max-width: ${ResponsiveBreakpoints.Large - 1 + 'px'}) {
    margin-top: ${({ theme }) => theme.size.space['09']};
  }
`

export const StatusInfo = styled.div`
  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.secondary};
`

export const SubsectionTitle = styled.div`
  ${h4Styles};
  color: ${({ theme }) => theme.color.content.secondary};
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

export const AttributeColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.size.space['02']} ${({ theme }) => theme.size.space['06']}
    ${({ theme }) => theme.size.space['02']} 0;
`

export const AttributeIcon = styled.div`
  margin-right: ${({ theme }) => theme.size.space['02']};
  display: flex;
`

export const AttributeLabel = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.content.secondary};
`

export const ValueColumn = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.content.main};
`

export const DocumentsValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.color.content.main};
`

export const StyledSummaryTable = styled.table`
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid #eee;
  }
  tr:last-child {
    border-bottom: 0;
  }
  td:first-child {
    width: 140px;
  }
`

export const StyledDocumentsTable = styled.table`
  border-collapse: collapse;
  tr {
    border-bottom: 1px solid #eee;
  }
  tr:last-child {
    border-bottom: 0;
  }
  td:first-child {
    width: 140px;
    display: flex;
    align-items: flex-start;
  }
`

export const StyledAnchor = styled.a`
  ${labelDefaultSemiboldStyles};
  text-decoration: none;
  color: ${({ theme }) => theme.color.feedback.action.default};
  margin-top: ${({ theme }) => theme.size.space['02']};
  display: flex;

  svg {
    margin-left: 4px;
    min-width: 16px;
    path {
      fill: ${({ theme }) => theme.color.feedback.action.default};
    }
  }
`

export const StyledRejectButton = styled(Button)`
  padding-left: ${({ theme }) => theme.size.space['06']};
  padding-right: ${({ theme }) => theme.size.space['06']};
  border: 0;
  color: ${({ theme }) => theme.color.feedback.danger.default};
  background-color: ${({ theme }) => theme.color.feedback.danger.subtle};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  margin-right: ${({ theme }) => theme.size.space['05']};
`
export const AlertTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`

export const ErrorWrapper = styled.div`
  ${bodySmallStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: ${({ theme }) => theme.color.content.secondary};
`

export const LoaderWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`
