import React from 'react'
import { Select } from '@lifebit-ai/lds'
import { Wrapper, RowsPerPage, TotalRows } from './TablePageSizeSelect.styles'

type Props = {
  total: number
  pageSize?: number
  setPageSize?: (pageSize: number) => void
  hideSizeSelect?: boolean
  sizeOptions?: { label: string; value: number }[]
}

const DEFAULT_SIZE_OPTIONS = [
  {
    label: '10',
    value: 10
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  }
]

const TablePageSizeSelect: React.FC<Props> = ({
  pageSize,
  setPageSize,
  total,
  hideSizeSelect,
  sizeOptions = DEFAULT_SIZE_OPTIONS
}) => {
  if (total === 0) return null

  return (
    <Wrapper>
      {!hideSizeSelect && (
        <>
          <RowsPerPage>Rows per page</RowsPerPage>
          <Select
            className="page-size-select"
            options={sizeOptions}
            value={pageSize}
            onChange={option => {
              if (setPageSize) setPageSize(option)
            }}
            compact={true}
          />
        </>
      )}

      <TotalRows className="total-rows">Total rows: {total}</TotalRows>
    </Wrapper>
  )
}

export default TablePageSizeSelect
