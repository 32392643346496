/**
 * Downloads a file using a temporary hidden <a> and an url.
 *
 * - If url is a normal(signed or not) url then a new tab opens and the download completes there.
 *    Saving the file locally requires user interaction (right-click).
 *
 * - If url is a DataURL or ObjectURL then it downloads and saves it directly with the provided filename, without opening a new tab.
 *
 * @param url string
 * @param downloadedFileName string
 */
export const download = (url: string, downloadedFileName: string) => {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', downloadedFileName)
  a.setAttribute('target', '_blank')
  a.setAttribute('style', 'visibility:hidden')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

/**
 * Generic function to download base64 encoded file (with or without mimetype prefix).
 * It decodes the base64, it creates a blob from it and downloads it using an Object URL and a hidden link.
 * https://stackoverflow.com/a/58158656
 * https://stackoverflow.com/a/16245768
 *
 *  TODO: this needs to be tested for all downloadable file formats that app supports
 *   and for the maximum expected file size
 *   and for each major target browser
 */
export const downloadBase64 = (base64EncodedFile: string, downloadedFileName: string, contentType?: string) => {
  if (!base64EncodedFile?.length) return

  // Check if mimetype prefix already embedded in the encoded data. This has priority over any contentType passed by caller.
  const mimeTypeFromPrefix = getMimeTypeFromBase64Prefix(base64EncodedFile)

  let blob: Blob

  if (mimeTypeFromPrefix) {
    // base64 with mimetype prefix found. Strip prefix from base64 data before decoding.
    blob = base64toBlob(base64EncodedFile.split(';base64,')[1], mimeTypeFromPrefix)
  } else {
    // prefix not found
    const mimeType = contentType || 'application/octet-stream'
    blob = base64toBlob(base64EncodedFile, mimeType)
  }
  const objectUrl = window.URL.createObjectURL(blob)
  download(objectUrl, downloadedFileName)
  window.URL.revokeObjectURL(objectUrl)
}

const base64toBlob = (b64Data: string, mimeType: string | undefined, sliceSize = 512): Blob => {
  const byteCharacters = window.atob(b64Data)
  const byteArrays = []
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, mimeType ? { type: mimeType } : {})
}

/**
 * Returns the mimetype part (e.g. application/pdf) from a base64 encoded data (or undefined if prefix does not exist).
 * Base64 with mimetype prefix has specific structure as:
 *  data:<mimetype>;base64,<actualData>
 */
const getMimeTypeFromBase64Prefix = (base64Data: string): string | undefined => {
  if (!base64Data.startsWith('data:')) {
    return undefined
  }
  return base64Data.substring(base64Data.indexOf(':') + 1, base64Data.indexOf(';'))
}
