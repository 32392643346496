import AppMode from 'AppMode'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { ErrorBoundary } from 'core/ErrorBoundary/ErrorBoundary'
import Navbar from 'modules/general/navbar/Navbar'

import { AppContentWithNavbar, StyledAppContainer } from './AppContainer.styles'

const routesWithoutNavbar = ['/login']

interface ContentProps {
  children: React.ReactNode
}

const AppContainer: React.FC<ContentProps> = ({ children }) => {
  const location = useLocation()
  const hasNavbar = !routesWithoutNavbar.includes(location.pathname)
  if (hasNavbar) {
    return (
      <StyledAppContainer>
        <AppMode />
        <Navbar />
        <ErrorBoundary>
          <AppContentWithNavbar id="jsAppContainer">{children}</AppContentWithNavbar>
        </ErrorBoundary>
      </StyledAppContainer>
    )
  }
  return <>{children}</>
}

export default AppContainer
