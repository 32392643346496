import React from 'react'

import { icons } from '@lifebit-ai/lds'

import ResultStatusTag from './ResultStatusTag'
import { TagsWrapper, TagIcon, TagLabel, TagWrapper } from './Results.styles'
import { formatDate } from '../../utils/date'

interface TagsProps {
  result: Result
}

const Tags: React.FC<TagsProps> = ({ result }) => (
  <TagsWrapper>
    <TagWrapper>
      <ResultStatusTag status={result?.status} />
    </TagWrapper>
    {!!result?.requestingUser?.email && (
      <TagWrapper>
        <TagIcon>
          <icons.UserSmall />
        </TagIcon>
        <TagLabel>
          Requested by {result?.requestingUser?.email} on {formatDate(result?.createdAt)}
        </TagLabel>
      </TagWrapper>
    )}
    {!!result?.workspaceName && (
      <TagWrapper>
        <TagIcon>
          <icons.LifebitSmall />
        </TagIcon>
        <TagLabel>{result?.workspaceName}</TagLabel>
      </TagWrapper>
    )}
  </TagsWrapper>
)

export default Tags
