import React, { useState } from 'react'
import { Alert, Button, Loader } from '@lifebit-ai/lds'

import { SubscriptionStatuses } from 'enums'
import useSubscription from 'hooks/useSubscription'
import { CommonContainer } from 'core/Common.styles'

import ApprovalModal from 'components/ApprovalModal/ApprovalModal'
import RejectionModal from 'components/RejectionModal/RejectionModal'
import Members from './Members'
import Summary from './Summary'
import Documents from './Documents'
import { buildSummaryItems, buildDocumentItems } from './helpers'

import {
  Actions,
  SectionContainer,
  NotificationContainer,
  StatusInfo,
  StyledRejectButton,
  SummaryAndDocuments,
  ErrorWrapper,
  LoaderWrapper
} from './SubscriptionOverview.styles'
import { Notifications } from './Notifications'
import { formatDate } from 'utils/date'

interface OverviewProps {
  subscriptionId: string
}

const SubscriptionOverview: React.FC<OverviewProps> = ({ subscriptionId }) => {
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState<boolean>(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false)

  const onClickReject = () => {
    if (!subscriptionId) return

    setIsRejectModalOpen(true)
  }

  const onClickApprove = () => {
    if (!subscriptionId) return

    setIsApprovalModalOpen(true)
  }

  const onApproveSuccess = () => {
    setIsApprovalModalOpen(false)
  }

  const onRejectSuccess = () => {
    setIsRejectModalOpen(false)
  }

  const {
    data: subscription,
    isLoading,
    isError,
    handleApprove,
    handleReject,
    isApprovalLoading,
    isRejectLoading,
    approvalHasError,
    rejectionHasError
  } = useSubscription(subscriptionId, onApproveSuccess, onRejectSuccess)

  const summaryItems = buildSummaryItems(subscription)
  const documentItems = buildDocumentItems(subscription)

  if (!subscriptionId) return null

  if (isLoading)
    return (
      <LoaderWrapper data-testid={'subscription-overview-loader'}>
        <Loader />
      </LoaderWrapper>
    )

  if (isError) return <ErrorWrapper>Error loading subscription.</ErrorWrapper>

  if (!subscription || Object.keys(subscription).length === 0)
    return <ErrorWrapper>Subscription is empty.</ErrorWrapper>

  return (
    <>
      <CommonContainer>
        <NotificationContainer>
          {subscription ? <Notifications data-testid="notifications" subscription={subscription} /> : null}
        </NotificationContainer>
        <SectionContainer>
          <SummaryAndDocuments>
            <Summary items={summaryItems} />
            <Documents
              subscriptionId={subscription._id}
              subscription={subscription}
              items={documentItems}
              data-testid="documents"
            />
          </SummaryAndDocuments>
          <Actions data-testid="actions">
            {subscription?.status === SubscriptionStatuses.Approved ? (
              <StatusInfo>{`Subscription request was approved on ${formatDate(subscription?.approvedAt)}`}</StatusInfo>
            ) : subscription?.status === SubscriptionStatuses.Rejected ? (
              <StatusInfo>{`Subscription request was rejected on ${formatDate(subscription?.rejectedAt)}`}</StatusInfo>
            ) : (
              <>
                <StyledRejectButton data-testid={'reject-button'} type="tertiary" onClick={onClickReject} size="large">
                  Reject
                </StyledRejectButton>
                <Button data-testid={'approve-button'} type="primary" onClick={onClickApprove} size="large">
                  Approve
                </Button>
              </>
            )}
          </Actions>
        </SectionContainer>
        <ApprovalModal
          onClose={() => setIsApprovalModalOpen(false)}
          isOpen={isApprovalModalOpen}
          isLoading={isApprovalLoading}
          action={handleApprove}
          isError={approvalHasError}
          title="Approve subscription request?"
          description={`Do you want to approve ${subscription?.organization} request for access to your data?`}
          content={
            <Alert type="info" showIcon={true}>
              Members on the subscription will not have instant access. You will grant access to them later.
            </Alert>
          }
          okTitle="Yes, approve"
        />
        <RejectionModal
          onClose={() => setIsRejectModalOpen(false)}
          isOpen={isRejectModalOpen}
          isLoading={isRejectLoading}
          action={handleReject}
          isError={rejectionHasError}
          title="Reject access?"
          description={`Do you want to reject ${subscription?.organization}'s request to access your data?`}
          okTitle="Reject access"
        />
      </CommonContainer>
      <Members
        subscriptionId={subscriptionId}
        subscriptionMembers={subscription.subscribers}
        subscriptionStatus={subscription.status}
        leadApplicantEmail={subscription.requestingUser.email}
      />
    </>
  )
}

export default SubscriptionOverview
