import { createGlobalStyle } from 'styled-components'

export const AppGlobalStyle = createGlobalStyle`
  #root {
    height: 100%;
  }
  .lds-ant-modal-title {
    padding-right: 30px;
  }
  .ant-dropdown-menu-item-disabled {
    svg path {
      fill: ${({ theme }) => theme.color.content.disabled};
    }
  }
`
