import { useEffect } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from 'services/queryKeys'
import { getSignedUrlForApprovedResult } from 'services/results'
import { GetSignedUrlForApprovedResultParams } from 'services/types'

export const useSignedUrlForApprovedResult = ({ resultId, token }: Partial<GetSignedUrlForApprovedResultParams>) => {
  const { data, isError, isSuccess, isLoading } = useQuery({
    queryKey: QueryKeys.GetSignedUrlForApprovedResult({ resultId, token }),
    queryFn: () => getSignedUrlForApprovedResult({ resultId: resultId ?? '', token: token ?? '' }),
    retry: false
  })

  useEffect(() => {
    if (!data?.signedUrl || !isSuccess) return

    const url = data?.signedUrl
    const a = document.createElement('a')

    const filePath = data.fileKey.split('/')
    a.href = url
    a.download = filePath[filePath.length - 1]
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove() //afterwards we remove the element again
  }, [isSuccess, data?.signedUrl, data?.fileKey])

  return { isError, isLoading }
}
