import styled from 'styled-components'
import { bodySmallStyles } from '@lifebit-ai/lds'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.size.space['08']};
`

export const Organisation = styled.div`
  color: ${({ theme }) => theme.color.content.main};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.heading.fontSize.large};
  font-family: ${({ theme }) => theme.font.family};
  margin-left: ${({ theme }) => theme.size.space['03']};
`
export const Separator = styled.div`
  margin: 0 5px;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: calc(${({ theme }) => theme.font.heading.fontSize.large} + 10px);
  color: ${({ theme }) => theme.color.content.secondary};
`

export const Dataset = styled.div`
  color: ${({ theme }) => theme.color.content.secondary};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.heading.fontSize.large};
  font-family: ${({ theme }) => theme.font.family};
`

export const LoaderWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  ${bodySmallStyles};
  color: ${({ theme }) => theme.color.content.secondary};
`
