import React from 'react'
import { icons } from '@lifebit-ai/lds'
import { MenuProps, Dropdown } from 'antd'

import { SubscriptionMemberStatuses, SubscriptionStatuses } from 'enums'

import { RowMenuTrigger } from './Members.styles'

interface MemberMenuProps {
  subscriptionMember: SubscriptionMember
  subscriptionStatus: SubscriptionStatuses
  onClickApprove: (subscriptionMember: SubscriptionMember) => void
  onClickRemove: (subscriptionMember: SubscriptionMember) => void
}

const MemberMenu: React.FC<MemberMenuProps> = ({
  subscriptionMember,
  subscriptionStatus,
  onClickApprove,
  onClickRemove
}) => {
  const rowMenuItems: MenuProps['items'] = []
  if (
    [
      SubscriptionMemberStatuses.PendingInvitation,
      SubscriptionMemberStatuses.PendingActivation,
      SubscriptionMemberStatuses.PendingApproval
    ].includes(subscriptionMember.status)
  ) {
    rowMenuItems.push({
      label: 'Confirm data access',
      key: `approve-${subscriptionMember.email}`,
      icon: <icons.UnlockSmall />,
      disabled: [SubscriptionMemberStatuses.PendingInvitation, SubscriptionMemberStatuses.PendingActivation].includes(
        subscriptionMember.status
      ),
      onClick: () => onClickApprove(subscriptionMember)
    })
  } else if (subscriptionMember.status === SubscriptionMemberStatuses.PendingRemoval)
    rowMenuItems.push({
      label: 'Confirm member removal',
      key: `confirm-remove-${subscriptionMember.email}`,
      icon: <icons.CloseInCircleSmall />,
      onClick: () => onClickRemove(subscriptionMember)
    })
  // TODO the following case is temporary and it should be removed when the Revoke api is ready.
  //  It gives the option to remove member from within Airlock.
  else if (subscriptionMember.status === SubscriptionMemberStatuses.Active)
    rowMenuItems.push({
      label: 'Remove member',
      key: `remove-${subscriptionMember.email}`,
      icon: <icons.CloseInCircleSmall />,
      onClick: () => onClickRemove(subscriptionMember)
    })
  // TODO uncomment this block when Revoke api is ready
  // else if (subscriptionMember.status === SubscriptionMemberStatuses.Active)
  //   rowMenuItems.push({
  //     label: 'Revoke data access',
  //     key: `revoke-${subscriptionMember.email}`,
  //     icon: <icons.CloseInCircleSmall />,
  //     onClick: () => onClickRevoke(subscriptionMember)
  //   })

  const menuProps = {
    items: rowMenuItems
  }

  if (subscriptionStatus !== SubscriptionStatuses.Approved || !rowMenuItems?.length)
    return <icons.EllipsesHorizontalSmall />

  return (
    <Dropdown menu={menuProps}>
      <RowMenuTrigger data-testid="members-row-menu-trigger">
        <icons.EllipsesHorizontalSmall />
      </RowMenuTrigger>
    </Dropdown>
  )
}

export default MemberMenu
