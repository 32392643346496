import { useContext } from 'react'

import { AuthContext } from 'auth/AuthContext'

const useUser = (): {
  login(username: string, password: string, otp?: string): Promise<UserAuth | { otp: boolean }>
  logout(): void
  user?: UserAuth
  refreshToken?: string
  tokenId?: string
  isLoading?: boolean
  isFetching?: boolean
} => {
  const context = useContext(AuthContext)

  if (!context) throw Error('Context still not initialised.')

  return {
    user: context.user,
    isLoading: context.isLoading,
    isFetching: context.isFetching,
    refreshToken: undefined, // TODO: Add logic to retrieve refresh token.
    tokenId: context.user?.token, // TODO: Add logic to get user token.
    login: context.login,
    logout: context.logout
  }
}

export default useUser
