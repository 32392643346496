import { useToast } from '@lifebit-ai/lds'
import React, { useState } from 'react'

import { getFileContent } from 'services/results'
import { download, downloadBase64 } from 'utils/download'
import { DownloadFileMethods } from 'enums'

import { DownloadIcon, StyledLoader } from './DownloadResultFile.styles'

interface Props {
  resultId: string
  bucket: string
  fileKey: string
  fileName: string
  method?: DownloadFileMethods
}

const DownloadResultFile: React.FC<Props> = ({
  resultId,
  bucket,
  fileKey,
  fileName,
  method = DownloadFileMethods.DownloadInTabWithWindowOpen
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast()

  const handleDownload = async () => {
    try {
      setIsLoading(true)
      toast({ message: 'Downloading…' })
      const fetchSignedUrlOnly = method !== DownloadFileMethods.DownloadDirectlyWithHiddenLink
      const data = await getFileContent(resultId, bucket, fileKey, fetchSignedUrlOnly)

      if ((!fetchSignedUrlOnly && !data?.fileContent?.length) || (fetchSignedUrlOnly && !data?.signedUrl?.length)) {
        toast({ message: 'Your download failed. If this issue persists, please contact your administrator.' })
        return
      }

      if (method === DownloadFileMethods.DownloadInTabWithWindowOpen) {
        window.open(data?.signedUrl, '_blank')
      } else if (method === DownloadFileMethods.DownloadInTabWithHiddenLink) {
        download(data?.signedUrl, fileName)
      } else if (method === DownloadFileMethods.DownloadDirectlyWithHiddenLink) {
        downloadBase64(data?.fileContent, fileName)
        toast({ message: 'Download completed' })
      }
    } catch (err) {
      toast({ message: 'Your download failed. If this issue persists, please contact your administrator.' })
    } finally {
      setIsLoading(false)
    }
  }

  // TODO/TBD: This is not in the designs. When downloading a file directly, an indicator should be displayed because
  //  the process can take a while for large files (though this method creates problems as the user is free to navigate
  //  when direct download is in progress).
  //  - toast.promise could be used to keep open the toast while downloading but is not avail in lds.
  //  - As a workaround, an inline loader is displayed in icon's place.
  if (method === DownloadFileMethods.DownloadDirectlyWithHiddenLink && isLoading) return <StyledLoader text={false} />

  return <DownloadIcon data-testid="download-file-icon" onClick={handleDownload} disabled={isLoading} />
}

export default DownloadResultFile
