import React from 'react'
import { Loader } from '@lifebit-ai/lds'
import { LoadingContainer } from './AppLoadingSpinner.styles'

interface LoadingSpinnerProps {
  text?: string
  transparent?: boolean
}

const AppLoadingSpinner: React.FC<LoadingSpinnerProps> = ({ text, transparent }) => (
  <LoadingContainer transparent={transparent}>
    <Loader text={text || 'Loading…'} />
  </LoadingContainer>
)

export default AppLoadingSpinner
