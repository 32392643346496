import { themes } from '@lifebit-ai/lds'
import { useClickOutside } from 'hooks/useClickOutside'
import useDebounce from 'hooks/useDebounce'
import React, { ReactNode, useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { PopoverContainer, ReferenceElementContainer, TooltipArrow } from './Popover.styles'

interface PopoverProps {
  children: ReactNode
  content: ReactNode
  placement?: 'top' | 'bottom' | 'right' | 'right-start' | 'right-end' | 'left'
  visible?: boolean
  showArrow?: boolean
  closeDelay?: number
  onClose?(): void
}

export const Popover: React.FC<PopoverProps> = ({
  content,
  children,
  placement,
  visible,
  showArrow,
  onClose,
  closeDelay = 500
}) => {
  const referenceElement = useRef(null)
  const popperElement = useRef(null)
  const arrowElement = useRef(null)

  const [auxVisible, setAuxVisible] = useState<boolean | undefined>(visible)
  const debouncedVisible = useDebounce(auxVisible, closeDelay)
  useClickOutside(popperElement, onClose)

  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8]
        }
      },
      { name: 'arrow', options: { element: arrowElement.current } },
      {
        name: 'preventOverflow',
        options: {
          altAxis: true
        }
      }
    ]
  })

  return (
    <div
      onMouseEnter={() => {
        if (visible !== undefined) return
        setAuxVisible(true)
      }}
      onMouseLeave={() => {
        if (visible !== undefined) return
        setAuxVisible(false)
      }}
    >
      <ReferenceElementContainer ref={referenceElement}>{children}</ReferenceElementContainer>
      <PopoverContainer
        ref={popperElement}
        style={styles.popper}
        {...attributes.popper}
        visible={visible || debouncedVisible}
      >
        {content}
        {showArrow && (
          <TooltipArrow ref={arrowElement} style={styles.arrow} backgroundColor={themes.Old.color.background.surface} />
        )}
      </PopoverContainer>
    </div>
  )
}
