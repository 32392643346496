import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px 0 0 0;
`

export const InnerWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.background.main};
`

export const StyledContent = styled.pre`
  display: flex;
  width: 100%;
  overflow: auto;
  max-height: 70vh;
  min-height: 800px;
`

export const TextFileContent = styled.div`
  width: 100%;
`
