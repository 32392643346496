import styled, { css } from 'styled-components'
import { DataExportIcon, GlobeIcon, SettingsIcon } from 'img'

export const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.color.background.surface};
  top: 0;
  left: 0;
  bottom: 0;
  width: 64px;
  max-width: 92px;
  box-shadow: ${({ theme }) => theme.shadow['02']};
`

export const NavLinks = styled.ul`
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: ${({ theme }) => theme.size.space['08']} 0 0 0;
  gap: ${({ theme }) => theme.size.space['06']};
`

export const NavHeader = styled.div`
  padding: ${({ theme }) => theme.size.space['06']} 0 0 0;
`

export const NavFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.base.neutral[30]};
`

export const NavLinkItem = styled.li<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: ${({ theme, active }) => (active ? theme.color.feedback.action.subtle : 'transparent')};

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  path,
  g {
    fill: ${({ theme, active }) => (active ? theme.color.feedback.action.default : theme.color.content.secondary)};
  }

  &:hover {
    background: ${({ theme, active }) => (active ? theme.color.feedback.action.subtle : theme.color.background.main)};

    path,
    g {
      fill: ${({ theme }) => theme.color.feedback.action.default};
    }
  }
`

export const ActionBtns = styled.div`
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

const iconStyle = css`
  width: ${({ theme }) => theme.size.icon.medium};
  height: ${({ theme }) => theme.size.icon.medium};

  &:hover {
    cursor: pointer;
  }
`

export const StyledActionBtn = styled.button`
  width: ${({ theme }) => theme.size.icon.medium};
  height: ${({ theme }) => theme.size.icon.medium};
  border: none;
  background: none;
`

export const StyledWireframeGlobeIcon = styled(GlobeIcon)`
  ${iconStyle}
`

export const StyledWorkspaceIcon = styled(SettingsIcon)`
  ${iconStyle}
  &:hover {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.color.content.main};
      transition: ${({ theme }) => theme.transition['02']};
    }
  }
`

export const StyledDataExportIcon = styled(DataExportIcon)`
  ${iconStyle}
`

export const HelpSection = styled.div`
  margin-top: ${({ theme }) => theme.size.space['06']};
  margin-bottom: ${({ theme }) => theme.size.space['06']};

  svg path {
    path {
      fill: ${({ theme }) => theme.color.content.secondary};
    }
  }

  &:hover {
    cursor: pointer;
    svg path {
      fill: ${({ theme }) => theme.color.content.main};
      transition: ${({ theme }) => theme.transition['02']};
    }
  }
`
