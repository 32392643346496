import { FileTypes } from 'enums'

import isObject from 'lodash/isObject'

export const validImageFileExtensions = [FileTypes.PNG, FileTypes.JPG, FileTypes.JPEG]

export const MAX_SIZE_IN_MB = 50

export const NO_CONTENTS_MESSAGE = 'No contents to display.'

export const getFileExtension = (fileName: string): string | null => {
  const match = fileName.match(/^[^?]*/)
  if (!match) return null

  const url = match[0]
  const splitFileName = url.split('.')
  return splitFileName.length ? splitFileName[splitFileName.length - 1] : null
}

export const getFileContent = (data: string | Record<string, string> | undefined): string => {
  if (!data) return NO_CONTENTS_MESSAGE
  if (isObject(data)) return JSON.stringify(data)

  // Stringifying the data makes sure edge cases returned by the backend are dealt with as well
  return data.toString()
}
