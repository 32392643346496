import React from 'react'

import { SubscriptionMemberStatuses, SubscriptionStatuses } from 'enums'

import MemberStatusIcon from './MemberStatusIcon'
import { StatusWrapper } from './MemberStatus.styles'

const getStatusText = (status: SubscriptionMemberStatuses): string => {
  switch (status) {
    case SubscriptionMemberStatuses.Active:
      return 'Access granted'
    case SubscriptionMemberStatuses.PendingApproval:
      return 'Confirm data access'
    case SubscriptionMemberStatuses.PendingActivation:
      return 'Pending'
    case SubscriptionMemberStatuses.PendingInvitation:
      return 'Pending'
    case SubscriptionMemberStatuses.PendingRemoval:
      return 'Confirm removal'
    case SubscriptionMemberStatuses.Disabled:
      // TODO/TBD This is not in the designs,
      //  however as status exists we should either exclude these users or display them with status
      return 'User removed'
    default:
      return 'Invalid status'
  }
}
interface MemberStatusProps {
  status: SubscriptionMemberStatuses
  subscriptionStatus: SubscriptionStatuses
}

const MemberStatus: React.FC<MemberStatusProps> = ({ status, subscriptionStatus }) => {
  if (subscriptionStatus !== SubscriptionStatuses.Approved) return <>--</>

  return (
    <StatusWrapper>
      <MemberStatusIcon status={status} />
      {getStatusText(status)}
    </StatusWrapper>
  )
}

export default MemberStatus
