import { Alert, Button, Label } from '@lifebit-ai/lds'
import { Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import {
  Buttons,
  Description,
  ErrorMsg,
  StyledForm,
  StyledInput,
  StyledModal,
  SubmitButton,
  Wrapper
} from './RejectionModal.styles'

interface RejectionModalProps {
  action(reason: string): void
  onClose(): void
  isLoading: boolean
  isError: boolean
  isOpen?: boolean
  title?: string
  description?: string
  cancelTitle?: string
  okTitle?: string
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required('Reason is required')
})

const RejectionModal: React.FC<RejectionModalProps> = ({
  onClose,
  isOpen,
  isLoading,
  isError,
  action,
  title = 'Reject request',
  description = 'Request will be rejected.',
  cancelTitle = 'Cancel',
  okTitle = 'Reject'
}) => {
  const onSubmit = (values: { reason: string }) => {
    action(values.reason)
  }

  const handleClickClose = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    if (typeof onClose === 'function') onClose()
  }

  return (
    <StyledModal
      data-testid="rejection-modal"
      open={isOpen}
      onCancel={onClose}
      size={'small'}
      title={title}
      footer={null}
      destroyOnClose
    >
      <Wrapper>
        <Description>{description}</Description>

        <Formik<{ reason: string }>
          validateOnMount
          initialValues={{ reason: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, handleChange, isValid }) => (
            <StyledForm>
              {isError && (
                <ErrorMsg>
                  <Alert showIcon type={'danger'}>
                    There was an error while rejecting. Please try again or contact the administrator.
                  </Alert>
                </ErrorMsg>
              )}

              <StyledInput
                textArea
                id="reason"
                data-testid={'reject-reason'}
                label={<Label>Please provide the reason for the rejection</Label>}
                placeholder="Reason"
                onChange={handleChange}
              />

              <Buttons>
                <Button
                  size={'large'}
                  type={'secondary'}
                  onClick={handleClickClose}
                  data-testid={'cancel-reject-button'}
                >
                  {cancelTitle}
                </Button>
                <SubmitButton
                  size={'large'}
                  loading={isLoading}
                  danger
                  htmlType={'submit'}
                  disabled={!isValid}
                  data-testid={'submit-reject-button'}
                >
                  {okTitle}
                </SubmitButton>
              </Buttons>
            </StyledForm>
          )}
        </Formik>
      </Wrapper>
    </StyledModal>
  )
}

export default RejectionModal
