import React from 'react'
import { Input } from '@lifebit-ai/lds'
import { useFormikContext } from 'formik'

import {
  StyledEyeIconHidden,
  StyledEyeIconShown,
  FormRow,
  StyledForm,
  StyledLabel,
  StepContentWrapper,
  AuthStepDescription
} from './BulkAuthModal.styles'
import { BulkAuthFormData } from './BulkAuthModal'

const EyeIconButton: React.FC<{ hidden: boolean; onClick: () => void }> = ({ hidden, onClick }) => {
  return hidden ? <StyledEyeIconHidden onClick={onClick} /> : <StyledEyeIconShown onClick={onClick} />
}

interface Props {
  isPasswordVisible: boolean
  setIsPasswordVisible: (value: boolean) => void
}

const AuthStep: React.FC<Props> = ({ isPasswordVisible, setIsPasswordVisible }) => {
  const { values, handleChange, errors, touched } = useFormikContext<BulkAuthFormData>()

  return (
    <StepContentWrapper>
      <StyledForm>
        <AuthStepDescription>For security reasons, please authenticate to confirm data access.</AuthStepDescription>
        <FormRow>
          <Input
            id="username"
            data-testid={'manager-username'}
            label={<StyledLabel htmlFor={'username'}>Email</StyledLabel>}
            value={values.username}
            placeholder="Email"
            onChange={handleChange}
            status={{
              //@ts-ignore
              type: touched?.username && !!errors.username ? 'error' : undefined,
              text: touched?.username && !!errors.username ? errors.username : undefined
            }}
          />
        </FormRow>
        <FormRow>
          <Input
            id="password"
            data-testid={'manager-password'}
            label={<StyledLabel htmlFor={'password'}>Password</StyledLabel>}
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder={isPasswordVisible ? 'password' : '*********'}
            value={values.password}
            onChange={handleChange}
            iconAfter={
              <EyeIconButton
                hidden={!isPasswordVisible}
                onClick={() => {
                  setIsPasswordVisible(!isPasswordVisible)
                }}
              />
            }
            status={{
              //@ts-ignore
              type: touched?.password && !!errors.password ? 'error' : undefined,
              text: touched?.password && !!errors.password ? errors.password : undefined
            }}
          />
        </FormRow>
      </StyledForm>
    </StepContentWrapper>
  )
}

export default AuthStep
