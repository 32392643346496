import styled from 'styled-components'
import { labelDefaultRegularStyles } from '@lifebit-ai/lds'
import { ResponsiveBreakpoints } from 'enums'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: -48px;
  .page-size-select {
    min-width: 56px;
  }

  // On smalls screens, "Total rows" should be displayed below "Rows per page" to save horiz. space
  @media (max-width: ${ResponsiveBreakpoints.Medium - 1}px) {
    align-items: flex-start;
    .total-rows {
      position: absolute;
      margin-top: 18px;
      margin-left: 0;
    }
    .page-size-select {
      margin-top: 4px;
    }
  }
`

export const RowsPerPage = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.main};
`

export const TotalRows = styled.div`
  ${labelDefaultRegularStyles};
  color: ${({ theme }) => theme.color.content.secondary};
  margin-left: 8px;
`
