import { useToast, icons } from '@lifebit-ai/lds'
import React, { useState } from 'react'

import { getAttachmentSignedUrl } from 'services/subscriptions'
import { StyledAnchor } from './SubscriptionOverview.styles'
import ApplicationModal from './ApplicationModal'

interface Props {
  subscriptionId: string
  subscription: Subscription
  attachment: Attachment
  isApplication?: boolean
}

const Attachment: React.FC<Props> = ({ subscriptionId, subscription, attachment, isApplication }) => {
  const [, setIsLoading] = useState(false)
  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState<boolean>(false)
  const toast = useToast()

  const handleDownload = async (attachment: Attachment) => {
    try {
      setIsLoading(true)
      const data = await getAttachmentSignedUrl(subscriptionId, attachment?.path)

      if (!data?.signedUrl) {
        toast({
          message:
            'Failed to retrieve required information about the requested file. If this issue persists, please contact your administrator.'
        })
        return
      }

      window.open(data?.signedUrl, '_blank')
    } catch (err) {
      toast({ message: 'Failed to open the file. If this issue persists, please contact your administrator.' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <StyledAnchor
        data-testid="open-attachment"
        onClick={() => (isApplication ? setIsApplicationModalOpen(true) : handleDownload(attachment))}
      >
        {isApplication ? (
          'Application form'
        ) : (
          <>
            {attachment?.name}
            <icons.OpenSmall />
          </>
        )}
      </StyledAnchor>
      {isApplicationModalOpen && (
        <ApplicationModal
          isOpen={isApplicationModalOpen}
          onClose={() => setIsApplicationModalOpen(false)}
          // TODO: on the application modal, the full subscription application (pdf?) should be displayed finally
          //  for the time being we display only the description field.
          content={subscription.application?.description}
        />
      )}
    </>
  )
}

export default Attachment
