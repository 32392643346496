import React from 'react'
import { AppContext } from './AppContext'

interface AppContextProviderProps {
  isSubscriptionManagementAvailable: boolean
  setIsSubscriptionManagementAvailable: (value: boolean) => void
  children: React.ReactNode
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  isSubscriptionManagementAvailable,
  setIsSubscriptionManagementAvailable
}) => {
  return (
    <AppContext.Provider value={{ isSubscriptionManagementAvailable, setIsSubscriptionManagementAvailable }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
