import React from 'react'
import { icons, Input } from '@lifebit-ai/lds'

type SearchProps = {
  handleChange: (searchTerm: string) => void
  term: string
  placeholder?: string
  testId?: string
  disabled?: boolean
}

const Search: React.FC<SearchProps> = ({ handleChange, term, placeholder, testId, disabled = false }) => {
  return (
    <Input
      data-testid={testId || 'search-input'}
      value={term}
      iconBefore={<icons.SearchSmall />}
      iconAfter={
        term && (
          <div onClick={() => handleChange('')}>
            <icons.CrossSmall />
          </div>
        )
      }
      placeholder={placeholder}
      onChange={(e: any) => handleChange(e?.target?.value)}
      disabled={disabled}
    />
  )
}

export default Search
