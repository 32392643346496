import React, { ReactElement } from 'react'
import {
  AttributeColumn,
  AttributeIcon,
  AttributeLabel,
  StyledSummaryTable,
  SubsectionTitle,
  StyledSummary,
  ValueColumn
} from './SubscriptionOverview.styles'

interface SummaryProps {
  items: Array<{ label: string; value?: string; icon: ReactElement }>
}

export const Summary: React.FC<SummaryProps> = ({ items }) => (
  <StyledSummary data-testid="summary">
    <SubsectionTitle>Summary</SubsectionTitle>
    <StyledSummaryTable>
      {items.map((it, index) => (
        <tr key={index}>
          <td>
            <AttributeColumn>
              <AttributeIcon>{it.icon}</AttributeIcon>
              <AttributeLabel>{it.label}</AttributeLabel>
            </AttributeColumn>
          </td>
          <td>
            <ValueColumn>{it.value}</ValueColumn>
          </td>
        </tr>
      ))}
    </StyledSummaryTable>
  </StyledSummary>
)

export default Summary
