export const setStorageValue = (name: string, value: string): void => {
  localStorage.setItem(name, value)
}

export const getStorageValue = (name: string): string | null => {
  return localStorage.getItem(name)
}

export const removeStorageValue = (name: string): void => {
  localStorage.removeItem(name)
}
