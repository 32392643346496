import styled, { DefaultTheme } from 'styled-components'
import { SubscriptionMemberStatuses } from 'enums'

const getStatusIconColor = (status: SubscriptionMemberStatuses, theme: DefaultTheme) => {
  switch (status) {
    case SubscriptionMemberStatuses.Active:
      return theme.color.feedback.success.default
    case SubscriptionMemberStatuses.PendingApproval:
      return theme.color.feedback.warning.outline
    case SubscriptionMemberStatuses.PendingActivation:
    case SubscriptionMemberStatuses.PendingInvitation:
      return theme.color.content.subtle
    case SubscriptionMemberStatuses.PendingRemoval:
      return theme.color.feedback.warning.outline
    case SubscriptionMemberStatuses.Disabled:
      return theme.color.feedback.danger.default
    default:
      return theme.color.content.main
  }
}

export const StatusIconWrapper = styled.div<{ status: SubscriptionMemberStatuses }>`
  display: flex;
  align-items: center;
  svg {
    path {
      fill: ${({ status, theme }) => getStatusIconColor(status, theme)};
    }
  }
`
