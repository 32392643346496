import { Tooltip } from '@lifebit-ai/lds'
import { AppContext } from 'AppContext'
import Envs, { castToStringOrUseDefault } from 'env'
import React, { useContext, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { Popover } from 'components/Popover/Popover'
import { AirlockLogo, NavHelpMediumIcon, PoweredByLogo } from 'img'

import {
  ActionBtns,
  HelpSection,
  NavFooter,
  NavHeader,
  NavLinkItem,
  NavLinks,
  NavbarWrapper,
  StyledActionBtn,
  StyledDataExportIcon,
  StyledWireframeGlobeIcon,
  StyledWorkspaceIcon
} from './Navbar.styles'
import SettingsPopup from './SettingsPopup'

type ActionBtnProps = {
  title: string
  onClick(): void
}

const ActionBtn: React.FC<ActionBtnProps> = ({ onClick }) => {
  return (
    <StyledActionBtn onClick={onClick}>
      <StyledWorkspaceIcon />
    </StyledActionBtn>
  )
}

const HelpLink: React.FC = () => {
  const helpDeskUrl = castToStringOrUseDefault(
    Envs.AIRLOCK_HELPDESK_URL,
    'https://lifebit.atlassian.net/wiki/spaces/CD/pages/1132068882/As+an+Airlock+Manager'
  )

  if (!helpDeskUrl) return null

  return (
    <HelpSection
      data-testid="navbar-help-link"
      onClick={() => {
        window.open(helpDeskUrl, '_blank')
      }}
    >
      <NavHelpMediumIcon />
    </HelpSection>
  )
}

const Navbar: React.FC = () => {
  const location = useLocation()
  const { isSubscriptionManagementAvailable } = useContext(AppContext)
  const [isWorkspacePopupVisible, setIsWorkspacePopupVisible] = useState<boolean>(false)

  const toggleWorkspacePopup = () => {
    setIsWorkspacePopupVisible(prev => !prev)
  }

  return (
    <NavbarWrapper>
      <NavHeader>
        <AirlockLogo />
      </NavHeader>
      <NavLinks>
        <Tooltip title="Data export requests" placement="right" zIndex={100}>
          <NavLinkItem
            active={location.pathname === '/export-requests/files' || location.pathname === '/export-requests/analysis'}
          >
            <NavLink to="/export-requests">
              <StyledDataExportIcon title="Data export requests" />
            </NavLink>
          </NavLinkItem>
        </Tooltip>
        {isSubscriptionManagementAvailable ? (
          <Tooltip title="Your subscribers" placement="right" zIndex={100}>
            <NavLinkItem active={location.pathname === '/subscriptions'}>
              <NavLink to="/subscriptions">
                <StyledWireframeGlobeIcon title="Your subscribers" />
              </NavLink>
            </NavLinkItem>
          </Tooltip>
        ) : null}
      </NavLinks>
      <ActionBtns data-testid="popover-btn">
        <Popover
          visible={isWorkspacePopupVisible}
          placement="right-start"
          content={<SettingsPopup onClosePopover={() => setIsWorkspacePopupVisible(false)} />}
          onClose={() => setIsWorkspacePopupVisible(false)}
        >
          <ActionBtn title="Account" onClick={toggleWorkspacePopup} />
        </Popover>
        <HelpLink />
      </ActionBtns>
      <NavFooter>
        <PoweredByLogo />
      </NavFooter>
    </NavbarWrapper>
  )
}

export default Navbar
