import React from 'react'
import { Buffer } from 'buffer'

import { ReportMessage, ReportWrapper, ResultContainer, StyledReportMessage } from './Results.styles'

interface ReportsProps {
  reports: {
    multiQc: string | undefined
    htmlFileContent: string | undefined
  }
}

const Reports: React.FC<ReportsProps> = ({ reports }) => {
  return (
    <>
      {reports.multiQc && (
        <ReportWrapper>
          <iframe
            id="MultiQC"
            title="MultiQC"
            name="MultiQC"
            width="100%"
            height="100%"
            srcDoc={Buffer.from(reports.multiQc, 'base64').toString()}
          />
        </ReportWrapper>
      )}
      {reports.htmlFileContent && (
        <ReportWrapper>
          <iframe
            data-testid={'html-iframe'}
            id="MultiQC"
            title="MultiQC"
            name="MultiQC"
            width="100%"
            height="100%"
            srcDoc={Buffer.from(reports.htmlFileContent, 'base64').toString()}
          />
        </ReportWrapper>
      )}
      {!reports.multiQc && !reports?.htmlFileContent && (
        <ResultContainer>
          <StyledReportMessage>
            <ReportMessage>The report of your analysis will appear here.</ReportMessage>
          </StyledReportMessage>
        </ResultContainer>
      )}
    </>
  )
}

export default Reports
