import React, { useState } from 'react'
import { useToast } from '@lifebit-ai/lds'
import { useMutation, useQueryClient } from 'react-query'

import { SubscriptionMemberActions, SubscriptionMemberStatuses, SubscriptionStatuses } from 'enums'
import { approveSubscriptionMembers, removeSubscriptionMember } from 'services/subscriptions'
import { AntdScrollableTable, AntdTableWrapperV2 } from 'core/AntdTable.styles'
import TablePageSizeSelect from 'components/TablePageSizeSelect'

import BulkAuthModal from '../bulk-auth-modal/BulkAuthModal'
import MemberStatus from './MemberStatus'
import MemberMenu from './MemberMenu'

import { MembersTableContainer, SubsectionTitle } from './SubscriptionOverview.styles'
import { formatDate } from 'utils/date'

export const PAGESIZE_OPTIONS = [
  {
    label: '7',
    value: 7
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  }
]

export const DEFAULT_PAGE_SIZE = PAGESIZE_OPTIONS[0].value
export const FIRST_PAGE = 1

interface MembersProps {
  subscriptionId: string
  subscriptionMembers: SubscriptionMember[]
  subscriptionStatus: SubscriptionStatuses
  leadApplicantEmail: string
}

const Members: React.FC<MembersProps> = ({
  subscriptionId,
  subscriptionMembers,
  subscriptionStatus,
  leadApplicantEmail
}) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const [tableState, setTableState] = useState<{ page: number; pageSize: number }>({
    page: FIRST_PAGE,
    pageSize: DEFAULT_PAGE_SIZE
  })
  const [isAuthModalOpenForApprove, setIsAuthModalOpenForApprove] = useState<boolean>(false)
  const [isAuthModalOpenForRemove, setIsAuthModalOpenForRemove] = useState<boolean>(false)
  const [memberToApprove, setMemberToApprove] = useState<SubscriptionMember | undefined>(undefined)
  const [memberToRemove, setMemberToRemove] = useState<SubscriptionMember | undefined>(undefined)
  const [approveHasError, setApproveHasError] = useState<boolean>(false)
  const [removeHasError, setRemoveHasError] = useState<boolean>(false)

  const { mutateAsync: removeMemberMutation, isLoading: isRemoveLoading } = useMutation<
    void,
    unknown,
    {
      subscriptionId: string
      member: SubscriptionMember
      managerUsername: string
      managerPassword: string
      isBulkOperation: boolean
    }
  >(
    ['remove-subscription-member', subscriptionId],
    async ({ subscriptionId, member, managerUsername, managerPassword, isBulkOperation }) => {
      if (!subscriptionId || subscriptionStatus !== SubscriptionStatuses.Approved) return

      await removeSubscriptionMember(subscriptionId, member.email, managerUsername, managerPassword)
    },
    {
      retry: false,
      onSuccess: () => {
        // Refetch subscription to refresh members
        queryClient.invalidateQueries(['subscription', subscriptionId])
        setIsAuthModalOpenForRemove(false)
        setMemberToRemove(undefined)
        setRemoveHasError(false)
      },
      onError: () => {
        setRemoveHasError(true)
      }
    }
  )

  const { mutateAsync: approveMemberMutation, isLoading: isApproveLoading } = useMutation<
    void,
    unknown,
    {
      subscriptionId: string
      member: SubscriptionMember
      managerUsername: string
      managerPassword: string
      isBulkOperation: boolean
    }
  >(
    ['approve-subscription-members', subscriptionId],
    async ({ subscriptionId, member, managerUsername, managerPassword, isBulkOperation }) => {
      if (!subscriptionId || subscriptionStatus !== SubscriptionStatuses.Approved) return

      let emailsOfTheSubscribers = []
      if (!isBulkOperation) {
        emailsOfTheSubscribers = [member.email]
      } else {
        emailsOfTheSubscribers = (
          subscriptionMembers?.filter(member => member.status === SubscriptionMemberStatuses.PendingApproval) || []
        ).map(member => member.email)
      }
      await approveSubscriptionMembers(subscriptionId, emailsOfTheSubscribers, managerUsername, managerPassword)
    },
    {
      retry: false,
      onSuccess: () => {
        // Refetch subscription to refresh members
        queryClient.invalidateQueries(['subscription', subscriptionId])
        setIsAuthModalOpenForApprove(false)
        setMemberToApprove(undefined)
        setApproveHasError(false)
        toast({ message: 'Data access confirmed' })
      },
      onError: () => {
        setApproveHasError(true)
      }
    }
  )

  const handleSetPageSize = (newPageSize: number) => {
    setTableState({ page: FIRST_PAGE, pageSize: newPageSize })
  }

  const handleRemoveMember = async (
    member: SubscriptionMember,
    managerUsername: string,
    managerPassword: string,
    isBulkOperation: boolean
  ) => {
    await removeMemberMutation({ subscriptionId, member, managerUsername, managerPassword, isBulkOperation })
  }

  const handleApproveMember = async (
    member: SubscriptionMember,
    managerUsername: string,
    managerPassword: string,
    isBulkOperation: boolean
  ) => {
    await approveMemberMutation({ subscriptionId, member, managerUsername, managerPassword, isBulkOperation })
  }

  const handleClickRemoveMember = (member: SubscriptionMember) => {
    setMemberToRemove(member)
    setIsAuthModalOpenForRemove(true)
  }

  const handleClickApproveMember = (member: SubscriptionMember) => {
    setMemberToApprove(member)
    setIsAuthModalOpenForApprove(true)
  }

  const columns = [
    {
      title: 'Authentication status',
      key: 'status',
      dataIndex: 'status',
      render: (text: string, subscriptionMember: any) => (
        <MemberStatus status={subscriptionMember.status} subscriptionStatus={subscriptionStatus} />
      )
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: 'Email address',
      key: 'email',
      dataIndex: 'email',
      width: '100%'
    },
    {
      title: 'Team',
      key: 'team',
      render: (text: string, subscriptionMember: any) => (
        <>{subscriptionMember.email === leadApplicantEmail ? 'Lead' : 'Member'}</>
      )
    },
    {
      title: 'Member since',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text: string, subscriptionMember: any) => <>{formatDate(subscriptionMember.createdAt)}</>
    },
    {
      title: ' ',
      key: 'actions',
      render: (text: string, subscriptionMember: any) => (
        <MemberMenu
          subscriptionMember={subscriptionMember}
          subscriptionStatus={subscriptionStatus}
          onClickApprove={handleClickApproveMember}
          onClickRemove={handleClickRemoveMember}
        />
      )
    }
  ]

  return (
    <MembersTableContainer>
      <SubsectionTitle>Members</SubsectionTitle>
      <AntdTableWrapperV2 data-testid="members-table-wrapper">
        <AntdScrollableTable
          data-testid="members-table"
          columns={columns}
          dataSource={subscriptionMembers}
          pagination={{
            showSizeChanger: false,
            pageSize: tableState.pageSize,
            current: tableState.page,
            total: subscriptionMembers?.length || 0,
            onChange: (pageNumber: number, pageSize) => {
              setTableState({ page: pageSize !== tableState.pageSize ? FIRST_PAGE : pageNumber, pageSize })
            }
          }}
        />
        <TablePageSizeSelect
          total={subscriptionMembers?.length || 0}
          pageSize={tableState.pageSize}
          setPageSize={handleSetPageSize}
          sizeOptions={PAGESIZE_OPTIONS}
        />
      </AntdTableWrapperV2>

      <BulkAuthModal
        actionType={SubscriptionMemberActions.Approve}
        isOpen={!!(isAuthModalOpenForApprove && memberToApprove)}
        isLoading={isApproveLoading}
        member={memberToApprove as SubscriptionMember}
        action={handleApproveMember}
        onClose={() => {
          setIsAuthModalOpenForApprove(false)
          setMemberToApprove(undefined)
          setApproveHasError(false)
        }}
        isError={approveHasError}
      />

      <BulkAuthModal
        actionType={SubscriptionMemberActions.Remove}
        isOpen={!!(isAuthModalOpenForRemove && memberToRemove)}
        isLoading={isRemoveLoading}
        member={memberToRemove as SubscriptionMember}
        action={handleRemoveMember}
        onClose={() => {
          setIsAuthModalOpenForRemove(false)
          setMemberToRemove(undefined)
          setRemoveHasError(false) // resets error state, so when modal reopens after an error, it will not show the previous error
        }}
        isError={removeHasError}
      />
    </MembersTableContainer>
  )
}

export default Members
