import React, { ReactElement } from 'react'

import Attachment from './Attachment'
import {
  AttributeColumn,
  AttributeIcon,
  AttributeLabel,
  StyledDocuments,
  DocumentsValueColumn,
  StyledDocumentsTable,
  SubsectionTitle
} from './SubscriptionOverview.styles'

interface DocumentsProps {
  subscriptionId: string
  subscription: Subscription
  items: Array<{ label: string; icon: ReactElement; attachments?: Attachment[]; application?: SubscriptionApplication }>
}

export const Documents: React.FC<DocumentsProps> = ({ subscriptionId, subscription, items }) => (
  <StyledDocuments>
    <SubsectionTitle>Documents</SubsectionTitle>
    {!items?.length ? (
      <AttributeLabel>No documents found</AttributeLabel>
    ) : (
      <StyledDocumentsTable>
        {items?.map((item, index) => (
          <tr key={`document-category___${index}`}>
            <td>
              <AttributeColumn>
                <AttributeIcon>{item.icon}</AttributeIcon>
                <AttributeLabel>{item.label}</AttributeLabel>
              </AttributeColumn>
            </td>
            <td>
              <DocumentsValueColumn>
                {/* Application */}
                {item.application ? (
                  <Attachment
                    subscriptionId={subscriptionId}
                    subscription={subscription}
                    //@ts-ignore
                    attachment={item.application?.attachment}
                    isApplication
                  />
                ) : null}
                {/*  Other attachments */}
                {item.attachments?.map((attachment, index) => (
                  <Attachment
                    key={`attachment-item___${index}`}
                    subscriptionId={subscriptionId}
                    subscription={subscription}
                    attachment={attachment}
                  />
                ))}
              </DocumentsValueColumn>
            </td>
          </tr>
        ))}
      </StyledDocumentsTable>
    )}
  </StyledDocuments>
)

export default Documents
