import { useToast } from '@lifebit-ai/lds'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import useUser from 'hooks/useUser'

import { Footer, SignoutBtn, StyledSignoutIcon, Wrapper } from './SettingsPopup.styles'

interface WorkspacesPopupProps {
  onClosePopover(): void
}

const SettingsPopup: React.FC<WorkspacesPopupProps> = ({ onClosePopover }) => {
  const navigate = useNavigate()
  const { logout } = useUser()
  const toast = useToast()

  return (
    <Wrapper>
      <Footer>
        <SignoutBtn
          onClick={() => {
            logout()
            toast({ message: 'Logout successful.' })
            onClosePopover()
            navigate('/login')
          }}
        >
          <StyledSignoutIcon />
          <span>Sign out</span>
        </SignoutBtn>
      </Footer>
    </Wrapper>
  )
}

export default SettingsPopup
