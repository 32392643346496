import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.size.space['08']};
`

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.content.main};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.heading.fontSize.medium};
  font-family: ${({ theme }) => theme.font.family};
  margin: 0 !important;
`

export const H5 = styled.h5`
  font-size: ${({ theme }) => theme.font.heading.fontSize.xsmall};
  color: ${({ theme }) => theme.color.content.main};
  font-family: ${({ theme }) => theme.font.family.main};
  font-weight: ${({ theme }) => theme.font.weight.semibold};
`

export const CommonContainer = styled.div`
  padding: ${({ theme }) => theme.size.space['06']};
  background: ${({ theme }) => theme.color.background.surface};
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
`

export const CommonPageWrapper = styled.div<{ centered?: boolean }>`
  padding: ${({ theme }) => theme.size.space['07']};
  margin: 0 ${({ centered }) => (centered ? 'auto' : 0)};
  width: 100%;
  max-width: ${({ centered }) => (centered ? '935px' : 'none')};
`

export const TableContainer = styled.div`
  padding: ${({ theme }) => theme.size.space['06']};
  padding-top: 0;
  background: ${({ theme }) => theme.color.background.surface};
  border-radius: ${({ theme }) => theme.size.border.radius['04']};
`
export const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.size.space['08']};
  background: ${({ theme }) => theme.color.content.contrast};
  border-radius: ${({ theme }) => theme.size.space['03']};
`

export const LoadingWrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.size.layout['02']} 0;
  > div {
    margin: auto;
  }
`
