export enum ResultsState {
  Complete = 'Approved',
  PendingVerification = 'PendingVerification',
  Rejected = 'Rejected'
}

export enum ResultsType {
  Analysis = 'analysis',
  FilesFolders = 'filesFolders'
}

export enum DateTimeFormats {
  Date = 'YYYY-MM-DD',
  Time = 'HH:mm:ss',
  DateTime = 'YYYY-MM-DD HH:mm:ss'
}

export enum Providers {
  AWS = 'AWS',
  GCP = 'GCP'
}

export enum SubscriptionStatuses {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
  ReSubmitted = 'ReSubmitted'
}

export enum SubscriptionMemberStatuses {
  PendingInvitation = 'pendingInvitation', // invite sent, not yet accepted
  PendingActivation = 'pendingActivation', // TBD (relevant to cloudOS member status)
  PendingApproval = 'pendingApproval', // waiting for approval by manager
  PendingRemoval = 'pendingRemoval', // a removal request has been done (outside airlock?) and waiting confirm
  Active = 'active', // member is approved by manager
  Disabled = 'disabled' // member has been removed from subscription permanently
  // TODO "access revoked" status should be added when api is ready
}

export enum SubscriptionMemberActions {
  Approve = 'Approve',
  ApproveExtra = 'ApproveExtra',
  Revoke = 'Revoke',
  Remove = 'Remove'
}

export enum SubscriptionTabViews {
  Overview = 'Overview',
  Cohorts = 'Cohorts',
  Analysis = 'Analysis'
}

export enum ResponsiveBreakpoints {
  Medium = 905,
  Large = 1240
}

export enum DownloadFileMethods {
  DownloadInTabWithWindowOpen = 'DownloadInTabWithWindowOpen',
  DownloadInTabWithHiddenLink = 'DownloadInTabWithHiddenLink',
  DownloadDirectlyWithHiddenLink = 'DownloadDirectlyWithHiddenLink'
}

export enum CurrencyCodes {
  USD = 'USD',
  USDcents = 'USDcents',
  GBP = 'GBP'
}

export enum ExportRequestStatuses {
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending'
}

export enum HttpStatusCodes {
  OK = 200,
  Created = 201,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  InternalServerError = 500
}

export enum FileTypes {
  PNG = 'png',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PDF = 'pdf',
  HTML = 'html'
}

export enum LoginTypes {
  MagicLink = 'magic-link'
}
