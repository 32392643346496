import styled, { css } from 'styled-components'
import { Button, Input, icons, bodySmallStyles, Checkbox } from '@lifebit-ai/lds'
import { Form } from 'formik'

export const Wrapper = styled.div`
  padding: 0;
`

export const StepContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.size.space['06']};
`

export const ReviewMessage = styled.div`
  ${bodySmallStyles};
  margin-bottom: ${({ theme }) => theme.size.space['05']};
  color: ${({ theme }) => theme.color.content.main};
`

export const AuthStepDescription = styled.div`
  ${bodySmallStyles};
  margin-bottom: ${({ theme }) => theme.size.space['06']};
  color: ${({ theme }) => theme.color.content.main};
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ theme }) => theme.size.space['04']};
  button:last-child {
    margin-left: ${({ theme }) => theme.size.space['04']};
  }
`

export const BackButton = styled(Button)<{ visible?: boolean }>`
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`

export const SubmitButton = styled(Button)`
  margin-left: ${({ theme }) => theme.size.space['06']};
`

export const ErrorMsg = styled.div`
  margin-top: ${({ theme }) => theme.size.space['05']};
  margin-bottom: ${({ theme }) => theme.size.space['05']};

  // TODO: this should be fixed in LDS
  [data-testid='alert-component'] {
    background-color: ${({ theme }) => theme.color.base.red['10']};
  }
`

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.font.body.fontSize.small};
  color: ${({ theme }) => theme.color.content.main};
  font-weight: ${({ theme }) => theme.font.body.fontWeight.normal};

  > div {
    margin-bottom: ${({ theme }) => theme.size.space['03']};
  }
`

export const StyledInput = styled(Input)`
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

export const StyledCheckbox = styled(Checkbox)`
  width: fit-content;
`

export const StyledTextArea = styled(Input)`
  height: 72px;
`

export const ReasonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.size.space['05']};
`

export const StyledForm = styled(Form)`
  display: block;
`

export const FormRow = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.size.space['06']};
`

const iconStyles = css`
  opacity: 1;
  path {
    fill: ${({ theme }) => theme.color.content.secondary};
  }
`

export const StyledEyeIconHidden = styled(icons.EyeHiddenSmall)`
  ${iconStyles}
`

export const StyledEyeIconShown = styled(icons.EyeSmall)`
  ${iconStyles}
`
