import styled from 'styled-components'

export const FormRow = styled.div<{ isInline?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ isInline }) => (isInline ? 'row' : 'column')};
  margin-bottom: ${({ theme }) => theme.size.layout['03']};
`

export const ButtonWrapper = styled.div`
  button {
    width: 100%;
    text-align: center;

    > span {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`
