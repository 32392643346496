import React from 'react'
import { Button, Alert } from '@lifebit-ai/lds'
import { StyledModal, Wrapper, Description, Content, Buttons, SubmitButton, ErrorMsg } from './ApprovalModal.styles'

interface ApprovalModalProps {
  title: string
  description?: string
  content?: React.ReactElement
  errorMessage?: string
  action(): void
  onClose(): void
  isLoading: boolean
  isError: boolean
  isOpen?: boolean
  cancelTitle?: string
  okTitle?: string
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({
  onClose,
  title = 'Approve request?',
  description = 'Do you want to approve request?',
  content,
  errorMessage = 'There was an error while approving request. Please try again or contact the administrator.',
  isOpen,
  isLoading,
  isError,
  action,
  cancelTitle = 'Cancel',
  okTitle = 'Approve'
}) => {
  return (
    <StyledModal
      data-testid="approval-modal"
      open={isOpen}
      onCancel={onClose}
      size={'small'}
      footer={null}
      title={title}
      destroyOnClose
    >
      <Wrapper>
        {isError && (
          <ErrorMsg>
            <Alert showIcon type={'danger'}>
              {errorMessage}
            </Alert>
          </ErrorMsg>
        )}

        <Description>{description}</Description>
        <Content>{content}</Content>

        <Buttons>
          <Button size={'large'} type={'secondary'} onClick={onClose} data-testid={'cancel-approve-button'}>
            {cancelTitle}
          </Button>
          <SubmitButton
            size={'large'}
            type={'primary'}
            onClick={action}
            loading={isLoading}
            data-testid={'submit-approve-button'}
          >
            {okTitle}
          </SubmitButton>
        </Buttons>
      </Wrapper>
    </StyledModal>
  )
}

export default ApprovalModal
