import styled from 'styled-components'
import {antdScrollableTableStyles} from 'core/AntdTable.styles'
import {Table} from 'antd'

export const SearchContainer = styled.div`
  padding-top: ${({ theme }) => theme.size.space['06']};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.size.layout['01']};

  > span {
    margin-bottom: 0;
  }

  input {
    min-width: 240px;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  min-height: 40px;
`

// Workaround to use typed table in combination with styled components
//  related to: https://github.com/styled-components/styled-components/issues/3752
export const StyledTypedTable = styled(Table<Subscription>)`
    ${antdScrollableTableStyles}
`
