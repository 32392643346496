import { Loader } from '@lifebit-ai/lds'
import { AppContext } from 'AppContext'
import React, { useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import useUser from 'hooks/useUser'
import ExportRequestsPage from 'modules/export-requests/ExportRequestsPage'
import NotFound from 'modules/general/not-found/NotFound'
import ResultsV2 from 'modules/resultsV2/Results'
import Subscription from 'modules/subscriptions/Subscription'
import SubscriptionsTable from 'modules/subscriptions/subscriptions-table/SubscriptionsTable'

import { LoadingPage } from './AirlockRoutes.styles'
import LoginMagicLink from './modules/login/LoginMagicLink'
import LoginMagicLinkCallback from './modules/login/LoginMagicLinkCallback'

/**
 * Represents a route that should only be viewed by a logged in user. If the user is not logged in, redirect to Login page.
 *  Users without teams are redirected to a page to create a team, unless `avoidWorkspacesRedirect=true` is specified.
 *
 * If a user has an invite token present and "staged" for accepting - the route redirects the user to accept it and removes
 *  the staged token from Local Storage.
 */
const PrivateRoute: React.FC<{ children: React.ReactNode; avoidWorkspacesRedirect?: boolean }> = ({ children }) => {
  const { user, isLoading: isLoadingUser } = useUser()
  if (isLoadingUser)
    return (
      <LoadingPage data-testid="app-loading">
        <Loader text="Loading..." />
      </LoadingPage>
    )
  // In case the user does not exist, just navigate them to the login page.
  if (!user) return <Navigate to="/login" />

  return <>{children}</>
}

/**
 * Represents a route for guests. This prevents logged-in users from accessing guest pages.
 */
const StrictlyPublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, isLoading: isLoadingUser } = useUser()
  if (isLoadingUser)
    return (
      <LoadingPage data-testid="app-loading">
        <Loader text="Loading..." />
      </LoadingPage>
    )
  if (!user) return <>{children}</>
  return <Navigate to="/" replace />
}

export const AirlockRoutes: React.FC = () => {
  const { isSubscriptionManagementAvailable } = useContext(AppContext)

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/login"
        element={
          <StrictlyPublicRoute>
            <LoginMagicLink />
          </StrictlyPublicRoute>
        }
      />
      <Route path="/auth/magic-login/callback" element={<LoginMagicLinkCallback />} />

      <Route path="/" element={<Navigate to={`/export-requests`} replace />} />
      <Route path="/dashboard" element={<Navigate to={`/export-requests`} replace />} />
      <Route path="/export-requests" element={<Navigate to={`/export-requests/files`} />} />

      <Route
        path="/export-requests/:tabId"
        element={
          <PrivateRoute>
            <ExportRequestsPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/export-requests/view/:resultId"
        element={
          <PrivateRoute>
            <ResultsV2 />
          </PrivateRoute>
        }
      />
      {isSubscriptionManagementAvailable ? (
        <>
          <Route
            path="/subscriptions"
            element={
              <PrivateRoute>
                <SubscriptionsTable />
              </PrivateRoute>
            }
          />
          <Route
            path="/subscriptions/:subscriptionId"
            element={
              <PrivateRoute>
                <Subscription />
              </PrivateRoute>
            }
          />
        </>
      ) : null}
    </Routes>
  )
}

export default AirlockRoutes
